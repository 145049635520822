import storage from './storage';

const backButtonTest = (params, config) => {
  const { siteName } = config.scData;

  const backSiteTestNames = [
    'govhomeprograms.com',
    'rent2ownqualifier.com',
    'yourvasurvey.info',
    'downpaymentsurvey.com',
  ];

  let backHeadlineZone;
  if (params.tyPageVersion && backSiteTestNames.includes(siteName)) {
    switch (params.tyPageVersion) {
      case '1':
        backHeadlineZone = '256619';
        break;
      case '2':
        backHeadlineZone = '256620';
        break;
      case '3':
        backHeadlineZone = '256621';
        break;
      default:
        backHeadlineZone = '256618';
    }
  } else {
    backHeadlineZone = '256618';
  }
  return backHeadlineZone;
};

const telThankyouTest = (params, config) => {
  const { siteName } = config.scData;
  const siteTestNames = [
    'downpaymentsurvey.com',
    'yourvasurvey.info',
    'rent2ownqualifier.com',
    'enhancedrelief.com',
    'govhomeprograms.com',
  ];
  const congratsContainer = document.querySelector('.congrats');

  if (congratsContainer) {
    if (params.tyPageVersion && siteTestNames.includes(siteName)) {
      switch (params.tyPageVersion) {
        case '1':
          congratsContainer.innerText = 'Success';
          break;
        default:
          congratsContainer.innerText = 'Congratulations';
      }
    } else {
      congratsContainer.innerText = 'Congratulations';
    }
  }
};

const zoneHeightTest = (config, coinFlipRes) => {
  let value;
  let result = false;
  const { siteName } = config.scData;
  const sites = [
    'roof.ratemarketplace.com',
    'usaroofingsurvey.com',
    'militarysurvey.com',
    'qualifiedroofingsurvey.com',
    'homewindowsurvey.com',
    'smartwindowssurveys.com',
    'militarywindowsurvey.com',
    'windows.ratemarketplace.com',
  ];

  // if (sites.includes(siteName)) {
  if (coinFlipRes && !result) {
    result = true;
    console.log('typ height test 1');
    storage('session', 'set', 'typVwoVariation', '1');
    value = true;
  } else {
    console.log('typ height control');
    storage('session', 'set', 'typVwoVariation', '0');
    value = false;
  }
  // }
  return value;
};

const headlineHeaderTest = (params, config) => {
  const testingSites = ['windows.ratemarketplace.com'];
  const testingVersions = ['1', '2'];
  let returnValue;
  if (
    testingSites.includes(config.surveyName) &&
    testingVersions.includes(params.tyPageVersion)
  ) {
    returnValue = true;
  } else {
    returnValue = false;
  }
  return returnValue;
};

const skeletonZoneTest = (params, config) => {
  const testingSites = [
    'downpaymentsurvey.com',
    'govhomeprograms.com',
    'homewindowsurvey.com',
    'homeroofingsurvey.com',
    'mycashoutquiz.com',
    'bathremodelspecialists.com',
    'fedsrateupdate.com',
    'americansolarhelp.com',
  ];
  const testingVersions = ['1'];
  let returnValue;
  if (
    testingSites.includes(config.surveyName) &&
    testingVersions.includes(params.tyPageVersion)
  ) {
    returnValue = true;
  } else {
    returnValue = false;
  }
  return returnValue;
};

export {
  backButtonTest,
  telThankyouTest,
  zoneHeightTest,
  headlineHeaderTest,
  skeletonZoneTest,
};
