const solarZipObject = {
  AZ: 6578,
  CA: 8346,
  CO: 9178,
  CT: 8008,
  DC: 10764,
  DE: 7254,
  FL: 7462,
  GA: 8554,
  ga: 8554,
  ID: 10608,
  IL: 8710,
  IN: 8268,
  IA: 8840,
  LA: 8710,
  MA: 8606,
  MD: 8814,
  ME: 7540,
  MI: 8918,
  MN: 8814,
  MT: 8398,
  NC: 8008,
  NH: 8866,
  NJ: 7358,
  NM: 9048,
  NV: 6630,
  NY: 9126,
  OH: 7566,
  OR: 8164,
  PA: 8476,
  RI: 8762,
  SC: 8502,
  TX: 8164,
  UT: 7748,
  VA: 8450,
  VT: 9022,
  WA: 7670,
  WI: 8580,
};

const finalFrameSolarHeadline = {
  AZ: 5566,
  CA: 7062,
  CO: 7766,
  CT: 6776,
  DC: 9108,
  DE: 6138,
  FL: 6314,
  GA: 7238,
  ID: 8976,
  IL: 7370,
  IN: 6996,
  IA: 7480,
  LA: 7370,
  MA: 7282,
  MD: 7458,
  ME: 6380,
  MI: 7546,
  MN: 7458,
  MT: 7106,
  NC: 6776,
  NH: 7502,
  NJ: 6226,
  NM: 7656,
  NV: 5610,
  NY: 7722,
  OH: 6402,
  OR: 6908,
  PA: 7172,
  RI: 7414,
  SC: 7194,
  TX: 6908,
  UT: 6556,
  VA: 7120,
  VT: 7634,
  WA: 6490,
  WI: 7260,
};

export { solarZipObject, finalFrameSolarHeadline };
