import styled from 'styled-components';
import styles from '../../utils/customStyles';

const customStyles = styles();

const ProgressContainer = styled.section`
  ${customStyles.ProgressContainer}
`;

const StatusBar = styled.div`
  ${customStyles.StatusBar}
`;

const ProgressCircleContainer = styled.section`
  ${customStyles.ProgressCircleContainer}
`;

const Clip1 = styled.div`
  ${customStyles.Clip1}
`;
const Clip2 = styled.div`
  ${customStyles.Clip2}
`;

const Slice1 = styled.div`
  ${customStyles.Slice1}
`;
const Slice2 = styled.div`
  ${customStyles.Slice2}
`;

const NumberStatus = styled.div`
  ${customStyles.NumberStatus}
`;
const FooterNumberStatus = styled.p`
  ${customStyles.FooterNumberStatus}
`;
const ProgressBarContainer = styled.div`
  ${customStyles.ProgressBarContainer}
`;
export {
  ProgressContainer,
  StatusBar,
  ProgressCircleContainer,
  Clip1,
  Clip2,
  Slice1,
  Slice2,
  NumberStatus,
  FooterNumberStatus,
  ProgressBarContainer,
};
