import { parse } from 'query-string';

const styles = () => {
  // eslint-disable-next-line
  const customStyles = require(`../../data/sites/${process.env.GATSBY_SITE_TYPE}/${process.env.GATSBY_SITE_NAME}/styles`);

  // eslint-disable-next-line
  let alternateStyles1 = {};
  let alternateStyles2 = {};
  let alternateStyles3 = {};
  let alternateStyles4 = {};
  let alternateVersion;
  // version 1
  try {
    // eslint-disable-next-line
    const alternateCustomStyles = require(`../../data/sites/${process.env.GATSBY_SITE_TYPE}/${process.env.GATSBY_SITE_NAME}/alternate-styles-1`);
    alternateStyles1 = alternateCustomStyles.default;
    window.altStyles = true;
  } catch (err) {
    // console.log('No custom styles for version 1');
  }
  // version 2
  try {
    // eslint-disable-next-line
    const alternateCustomStyles = require(`../../data/sites/${process.env.GATSBY_SITE_TYPE}/${process.env.GATSBY_SITE_NAME}/alternate-styles-2`);
    alternateStyles2 = alternateCustomStyles.default;
    window.altStyles = true;
  } catch (err) {
    // console.log('No custom styles for version 2');
  }
  // version 3
  try {
    // eslint-disable-next-line
    const alternateCustomStyles = require(`../../data/sites/${process.env.GATSBY_SITE_TYPE}/${process.env.GATSBY_SITE_NAME}/alternate-styles-3`);
    alternateStyles3 = alternateCustomStyles.default;
    window.altStyles = true;
  } catch (err) {
    // console.log('No custom styles for version 3');
  }
  // version 4
  try {
    // eslint-disable-next-line
    const alternateCustomStyles = require(`../../data/sites/${process.env.GATSBY_SITE_TYPE}/${process.env.GATSBY_SITE_NAME}/alternate-styles-4`);
    alternateStyles4 = alternateCustomStyles.default;
    window.altStyles = true;
  } catch (err) {
    // console.log('No custom styles for version 4');
  }

  if (typeof window !== 'undefined') {
    const params = window.location.search;
    const parsedParams = parse(params);
    const jtkResult = window?.jtkResults;

    if (parsedParams?.version || jtkResult) {
      if (
        (parsedParams?.version === '1' ||
          jtkResult?.result?.challenger === '1') &&
        Object.entries(alternateStyles1)?.length > 0
      ) {
        alternateVersion = alternateStyles1;
      } else if (
        (parsedParams?.version === '2' ||
          jtkResult?.result?.challenger === '2') &&
        Object.entries(alternateStyles2)?.length > 0
      ) {
        alternateVersion = alternateStyles2;
      } else if (
        (parsedParams?.version === '3' ||
          jtkResult?.result?.challenger === '3') &&
        Object.entries(alternateStyles3)?.length > 0
      ) {
        alternateVersion = alternateStyles3;
      } else if (
        (parsedParams?.version === '4' ||
          jtkResult?.result?.challenger === '4') &&
        Object.entries(alternateStyles4)?.length > 0
      ) {
        alternateVersion = alternateStyles4;
      } else {
        alternateVersion = customStyles.default;
      }
    } else {
      alternateVersion = customStyles.default;
    }
  } else {
    alternateVersion = customStyles.default;
  }

  if (!customStyles) {
    throw new Error(`
  No Custom Styles Found For: ${process.env.GATSBY_SITE_NAME} \n
  Check that the GATSBY_SITE_NAME environment variable has been set and that the folder exists in the './data' directory.
  `);
  } else {
    return alternateVersion;
  }
};

export default styles;
