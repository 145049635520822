const g4EventTracking = (key, value) => {
  try {
    if (window.gtag) {
      window.gtag('event', key, value);
    }
  } catch (error) {
    console.log(error);
    // do nothing
  }
};

const g4PageTracking = () => {
  try {
    if (window.gtag) {
      // do stuff
    }
  } catch (error) {
    console.log(error);
    // do nothing
  }
};

export { g4EventTracking, g4PageTracking };
