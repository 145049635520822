/**
 * Found this example in an article here
 * https://blog.hackages.io/conditionally-wrap-an-element-in-react-a8b9a47fab2
 * @param {*} condition -- what determines if the component is wrapped or not
 * @param {*} wrapper -- what wraps around the component if the condition is met
 * @param {*} children -- what is rendered between the component
 */

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

export default ConditionalWrapper;
