import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import styles from '../../utils/customStyles';
import storage from '../../utils/storage';
import Operator from '../../images/Operator.png';

const customStyles = styles();

const AbandonModalContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  .modalOverlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
  }
  .modalContent {
    position: relative;
    width: calc(100% - 40px);
    max-width: 550px;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    border: 2px solid #000;
  }
  .modalHeader {
    font-size: clamp(22px, 4vw, 30px);
    font-weight: 700;
  }
  .modalSubhead {
    font-size: clamp(16px, 2.5vw, 22px);
  }
  .closeButton {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #676767;
    padding: 0;
    background: transparent;
    color: #676767;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    p {
      margin: -3px 0 0 1px;
      padding: 0;
    }
  }
  .modalBody {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .buttons {
    width: 100%;
  }
  .radioButton {
    display: none;
  }

  .button {
    display: flex;
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    border: none;
    color: #fff;
    background: #de7733;
    border-radius: 5px;
    font-weight: 700;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    &.submitButton {
      width: 240px;
      font-size: 22px;
    }
  }
  .Operator {
    width: 100%;
  }
  .feedback {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
  }
  .characterCount {
    font-size: 14px;
    margin: 0 0 5px 0;
    color: #676767;
  }
  .feedbackText {
    width: 100%;
    min-height: 150px;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
  }
  .noThanks {
    background: transparent;
    border: none;
    text-decoration: underline;
  }
  ${customStyles.AbandonModal}
`;

const AbandonModal = ({ setShowAbandon, config, mouse }) => {
  const [count, setCount] = useState(0);
  const [isInitial, setIsInitial] = useState(true);
  const [userFeedback, setUserFeedback] = useState('');
  const [countDown, setCountDown] = useState(false);

  const closeAbandonModal = () => {
    setShowAbandon(false);
    setIsInitial(true);
    setCount(0);
    storage('local', 'set', 'hideAbadonModal', true);
  };

  const handleKeyDown = e => {
    // check keys if you want
    if (e.keyCode === 27) {
      closeAbandonModal();
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setShowAbandon(false);
    setIsInitial(true);
    storage('local', 'set', 'hideAbadonModal', true);
  };

  const handleFeedbackClick = e => {
    setIsInitial(false);
  };

  const handleChange = e => {
    setUserFeedback(e.target.value);
    setCount(e.target.value.length);
  };

  useEffect(() => {
    let isMounted = true;
    setTimeout(() => {
      if (isMounted) {
        setCountDown(true);
      }
    }, 5000);

    return () => {
      isMounted = false;
    };
  }, [config]);

  useEffect(() => {
    if (mouse.pageY < 10 && mouse.pageY !== null && countDown) {
      const hideAbadonModal = storage('local', 'get', 'hideAbadonModal');
      if (hideAbadonModal !== 'true') {
        setShowAbandon(true);
      }
    }
  }, [mouse.pageY, countDown, setShowAbandon]);

  return (
    <AbandonModalContainer>
      <div
        className="modalOverlay"
        onClick={closeAbandonModal}
        onKeyDown={handleKeyDown}
        role="none"
      />
      <div className="modalContent">
        <button
          type="button"
          className="closeButton"
          onClick={closeAbandonModal}
          onKeyDown={handleKeyDown}
        >
          <p>&times;</p>
        </button>
        <div className="modalHeaderContainer">
          <p className="modalHeader">
            {isInitial ? `We're sorry to see you go!` : `Any other feedback?`}
          </p>
          <p className="modalSubhead">
            {isInitial
              ? `Please tell us more about how we could improve.`
              : `We'd love you to give us your thoughts.`}
          </p>
        </div>
        <div className="modalBody">
          {isInitial ? (
            <div className="buttons">
              <input
                type="radio"
                id="notExpected"
                name="exitReason"
                value="Page was not what I expected"
                className="radioButton"
                onClick={handleFeedbackClick}
              />
              <label htmlFor="notExpected" className="button">
                Page was not what I expected
              </label>

              <input
                type="radio"
                id="isForm"
                name="exitReason"
                value="I didn't know this was a form"
                className="radioButton"
                onClick={handleFeedbackClick}
              />
              <label htmlFor="isForm" className="button">
                I didn't know this was a form
              </label>

              <input
                type="radio"
                id="notIntended"
                name="exitReason"
                value="I didn't intend to leave"
                className="radioButton"
                onClick={closeAbandonModal}
                onKeyDown={handleKeyDown}
              />
              <label htmlFor="notIntended" className="button">
                I didn't intend to leave
              </label>

              <input
                type="radio"
                id="dontRecognize"
                name="exitReason"
                value="I don't recognize this brand"
                className="radioButton"
                onClick={handleFeedbackClick}
              />
              <label htmlFor="dontRecognize" className="button">
                I don't recognize this brand
              </label>

              <input
                type="radio"
                id="other"
                name="exitReason"
                value="Other"
                className="radioButton"
                onClick={handleFeedbackClick}
              />
              <label htmlFor="other" className="button">
                Other
              </label>
            </div>
          ) : (
            <div className="feedback">
              <p className="characterCount">
                {240 - count} characters remaining
              </p>
              <textarea
                className="feedbackText"
                id="feedback"
                placeholder="Please give us your feedback, if you'd care to (up to 240 characters)"
                maxLength="240"
                onChange={handleChange}
              />
              <button
                type="button"
                className="button submitButton"
                onClick={handleSubmit}
                onKeyDown={handleSubmit}
              >
                Share My Feedback
              </button>
              <button
                type="button"
                className="noThanks"
                onClick={closeAbandonModal}
              >
                No thanks
              </button>
            </div>
          )}
          <div className="operator">
            <img src={Operator} alt="operator" />
          </div>
        </div>
      </div>
    </AbandonModalContainer>
  );
};

export default AbandonModal;
