import React from 'react';
import styled, { keyframes } from 'styled-components';
import { getData } from '../../utils/utilityFunctions';

const pulse = keyframes`
0% {transform: scale(1);}
50% {transform: scale(1.3);}
100% {transform: scale(1);}
`;

const ArrowContainer = styled.div`
  margin-bottom: 0;
  animation: ${pulse} 2s infinite;
  i {
    border: solid black;
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 8px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    font-size: 24px;
  }

  &.lastArrow {
    margin-bottom: 10px;
  }
`;

const TyPageHeadlineContainer = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=News+Cycle&display=swap');
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  background: #fff;
  padding: 0 5px;

  font-family: 'News Cycle', sans-serif !important;
`;

const Headline = styled.h1`
  margin-top: 10px;
  color: #3c83f6;
  text-shadow: 2px 2px #d3d3d3;
  margin-bottom: 5px;

  font-family: 'News Cycle', sans-serif !important;
`;

const SubHead = styled.h3`
  text-align: center;
  @media (max-width: 801px) {
    font-size: 15px;
  }

  font-family: 'News Cycle', sans-serif !important;
`;

const Ptag = styled.p`
  margin-bottom: 15px;

  font-family: 'News Cycle', sans-serif !important;

  @media (max-width: 801px) {
    font-size: 14px;
  }
`;

const tyHeader = ({ config, tyPageVersion }) => {
  const setSubHead = () => {
    let headline;
    const firstName = getData('first_name') || '';
    if (firstName) {
      headline = `${firstName}, your window results are being prepared.`;
    } else {
      headline = 'Your window results are being prepared.';
    }
    return headline;
  };

  return (
    <TyPageHeadlineContainer
      id={
        tyPageVersion
          ? `tyPageHeadlineContainer-${tyPageVersion}`
          : 'tyPageHeadlineContainer'
      }
    >
      <Headline>Success!</Headline>
      <SubHead>{setSubHead()}</SubHead>
      <Ptag>Compare your unique results below.</Ptag>
      {/* <ArrowContainer>
        <i />
      </ArrowContainer>
      <ArrowContainer className="lastArrow">
        <i />
      </ArrowContainer> */}
    </TyPageHeadlineContainer>
  );
};

export default tyHeader;
