import { useStaticQuery, graphql } from 'gatsby';

export const useLayoutQueries = () => {
  const data = useStaticQuery(graphql`
    query CONFIG_QUERY {
      config {
        title
        poorCreditEject
        offerId
        globalSign
        creditRatingTest
        sslImage
        fontFamily
        rateInHeadline
        tyPageShimmer
        upsellPhoneTest
        goExperimentID
        noWeightAndHeight
        alternateButton
        alternateButtontext
        fontText
        stepCount
        noExtraStep_test
        hideStepCountOnFirstFrame
        stepOverviewInHeadline
        stepOverviewAboveHeadline
        stepOverview
        BlueModoMedia
        rateZoneTest
        LabelAsPlaceholder
        PhonePrePop_test
        fairCreditQuestion
        windowCountTest
        HowThisWorksTitle
        allElectricProviderButtons
        ElectricBillButtonsByState
        EstPurchaseDynamicStateSubHeadline
        step1
        step2
        step3
        step4
        step5
        step6
        cat1
        cat2
        cat3
        cat4
        cat5
        phoneOnSite
        singleValCard
        maMedZones
        maHealthZones
        anuraTrack
        newMedTestDisclosure
        ipApiTest
        earlyIlxLoad
        tyAnura_Test
        siteLogo
        interstitialTime
        mobileScrollOnError
        zipTip
        upsideInsurance
        phonePlaceholder_test
        logoClickRedirectUrl
        singleValCardReverse
        singleLoanValCard
        validEmailCheckbox
        mediaAlphaQnstSite
        nameAddressCombined_test
        clickToCallTest
        bdateWithDiv
        vehicleDropDown
        healthBreakOutPii
        MedicareEnrolled_test
        windowRepairToReplace
        buttonsWithParagraph
        acnDropdown
        homeInsuranceUpsellTest
        alternateAcn
        ExtraCashAlt
        ExtraCashAlt_1
        ExtraCashAlt_2_test
        howThisWorks
        howThisWorksPosition
        gTag
        electricBillButtons
        dynamicWindowsQuestions_test
        affiliateTestingBan
        upsellProviderButton_test
        zipSubtitle
        gtmContainer
        zipPlaceholder
        manufacturedRedirect
        newsletterCheckbox
        separateEmail
        separateAddress
        njZipCodeTest
        sameCity
        sameCityZip
        showCoverageTypeText
        coverageTypeAltText_test
        privatePolicyCheckbox
        smsDisclosure
        headerBackgroundImage
        showAbandonerPopup
        dailyEmailCheckbox
        phoneValidation
        slideAnimation
        customHomePage
        hasSolarEject
        shadeEjection
        emailDisclaimer
        iLeads
        isSolar
        isSCLabs
        changeAge
        submitButtonDisclosure
        noCongrats
        headline
        rmpSite
        subhead
        skipZip
        skipIncome_test
        dualPageForm
        tyPageTest
        healthZipTest
        addReverse
        source
        sourceLocation
        addressSubtext
        singlePageHeader
        doNotRunTCPA
        surveyType
        surveyName
        noPlaceholder
        noEnterOnPlaceholder_test
        noDefaultCheck
        singleHomePage
        advertisingDisclosure
        organicLink
        verifyLink
        tipLocation
        emailLabel
        excellentCreditFF_test
        excellentCreditQuestionTitle_test
        excellentCreditQuestionSubtitle_test
        doSolarUpsell
        doWindowUpsell
        RestrictedElectricProvider
        ExtendedRestrictedElectricProvider
        headlineLocation
        keepHeadline
        keepHeadlineInFinalFrame
        headlineAlwaysInFirstFrame
        persistentMessage
        persistentMessage2
        persistentMessageLocation
        showLogos
        homeInsurance
        frameAnimation
        breadCrumbs
        breadCrumbDividerIcon
        noGlobalSignFirstFrame
        submitButton
        firstFrameNextButton
        firstFrameNextButtonHelper
        submitButtonIcon
        nextButton
        nextButtonIcon
        backButton
        backButtonIcon
        progressBar
        progressBarHideFF
        altFFHeadline
        choiceImage
        yearsLeastToGreatest_test
        yearSplit_test
        ipHeadline
        ipSubhead
        dynamicSubheadA
        dynamicSubheadB
        ipSubPattern
        choiceAlphabet
        ipZip
        personalizedFF
        sliders
        dropdown
        dropdownToMultipleChoice
        selectMultipleChoiceOptions
        reverseDropdownLTV
        limitedUtils
        buttonDisabled
        addressAutoComplete
        submitButtonAnimation
        submitModalAnimation
        allowRedirect
        initialValue
        bgImage
        additionalCashAsEquity
        frontPageAd_test
        showTcpaOutsideForm
        showTcpaAboveButton
        additionalFooterText1
        additionalFooterText2
        additionalFooterText3
        additionalFooterText4
        additionalHeadlineText1
        additionalHeadlineText2
        additionalHeadlineText3
        additionalHeadlineText4
        additionalHeadlineText5
        additionalHeadlineText6
        additionalHeadlineText7
        additionalHeadlineText8
        additionalHeadlineText9
        PoweredByLogo
        additionalFooterBeforeDisclosure
        runZippoFromParam
        showVehicleSubtitle
        genderNoAnswer
        birthdayNoAnswer
        companyAddress
        locationOption
        dynamicHeadlineA
        dynamicHeadlineB
        scData {
          siteName
          siteNameV2
          siteVertical
        }
        dynamicSites {
          ipHeadline
          locationOption
          dynamicHeadlineA
          dynamicHeadlineB
          dynamicSurveyName
          persistentMessage
          gTag
          submitModalAnimation
          persistentMessageLocation
          fontFamily
          fontText
          headline
          subhead
          BlueModoMedia
          keepHeadline
          ipHeadline
          MedicareEnrolled_test
          source
          isSCLabs
          anuraTrack
          newMedTestDisclosure
          gtmContainer
          useTYPLayout
          showLogos
          homeInsurance
          zipTip
          upsideInsurance
          phonePlaceholder_test
          LabelAsPlaceholder
          siteLogo
          interstitialTime
          bgImage
          offerId
          limitedUtils
          advertisingDisclosure
          additionalFooterText1
          additionalFooterText2
          additionalFooterText3
          additionalFooterText4
          favicon
          scData {
            siteName
          }
          organicLink
          title
        }
        inactiveModal
        propZipFollowUp
        noBack
        noButtons
        noNextButtons
        backButtonNoMultipleChoice
        mobileBackButtonNoMultipleChoice
        noChangeAddress
        firstQuestion
        cashoutPath_test
        blurredRates
        defaultDelta
        countdown
        countdownText
        countdownSubtext
        countdownCloseButton
        countdownHideOnFF
        verifyPage
        useTYPLayout
        pathChoiceOptions {
          label
          value
          bgImage
        }
        propertyTypeOptions {
          label
          value
        }
        setInitialValues {
          title
          value
        }
        finalFrameOptions {
          label
          value
        }
        hiddenFields {
          question
          value
        }
        dynamicAnswers {
          title
          options {
            label
            value
            bgImage
          }
        }
        combinedQuestions {
          title
          questions
        }
      }

      alternateConfig1 {
        title
        poorCreditEject
        offerId
        globalSign
        creditRatingTest
        noWeightAndHeight
        alternateButton
        alternateButtontext
        sslImage
        fontFamily
        tyPageShimmer
        fontText
        stepCount
        noExtraStep_test
        hideStepCountOnFirstFrame
        stepOverviewInHeadline
        stepOverviewAboveHeadline
        stepOverview
        step1
        step2
        step3
        step4
        step5
        step6
        cat1
        cat2
        cat3
        cat4
        cat5
        phoneOnSite
        ipApiTest
        mobileScrollOnError
        PhonePrePop_test
        fairCreditQuestion
        windowCountTest
        HowThisWorksTitle
        earlyIlxLoad
        ElectricBillButtonsByState
        EstPurchaseDynamicStateSubHeadline
        upsellPhoneTest
        maMedZones
        maHealthZones
        clickToCallTest
        bdateWithDiv
        BlueModoMedia
        rateZoneTest
        LabelAsPlaceholder
        mediaAlphaQnstSite
        nameAddressCombined_test
        limitedUtils
        singleValCard
        zipTip
        upsideInsurance
        phonePlaceholder_test
        healthBreakOutPii
        tyAnura_Test
        headerBackgroundImage
        homeInsuranceUpsellTest
        siteLogo
        interstitialTime
        goExperimentID
        MedicareEnrolled_test
        windowRepairToReplace
        logoClickRedirectUrl
        anuraTrack
        newMedTestDisclosure
        singleValCardReverse
        singleLoanValCard
        validEmailCheckbox
        vehicleDropDown
        buttonsWithParagraph
        acnDropdown
        alternateAcn
        ExtraCashAlt
        ExtraCashAlt_1
        ExtraCashAlt_2_test
        howThisWorks
        howThisWorksPosition
        gTag
        electricBillButtons
        dynamicWindowsQuestions_test
        tyPageTest
        healthZipTest
        affiliateTestingBan
        zipSubtitle
        gtmContainer
        upsellProviderButton_test
        zipPlaceholder
        manufacturedRedirect
        newsletterCheckbox
        separateEmail
        separateAddress
        njZipCodeTest
        sameCity
        sameCityZip
        showCoverageTypeText
        coverageTypeAltText_test
        privatePolicyCheckbox
        smsDisclosure
        dailyEmailCheckbox
        phoneValidation
        slideAnimation
        customHomePage
        hasSolarEject
        shadeEjection
        emailDisclaimer
        iLeads
        isSolar
        isSCLabs
        changeAge
        submitButtonDisclosure
        allElectricProviderButtons
        noCongrats
        headline
        rmpSite
        subhead
        skipZip
        skipIncome_test
        dualPageForm
        addReverse
        source
        sourceLocation
        addressSubtext
        singlePageHeader
        doNotRunTCPA
        surveyType
        surveyName
        noPlaceholder
        noEnterOnPlaceholder_test
        noDefaultCheck
        singleHomePage
        advertisingDisclosure
        organicLink
        verifyLink
        tipLocation
        emailLabel
        excellentCreditFF_test
        excellentCreditQuestionTitle_test
        excellentCreditQuestionSubtitle_test
        doSolarUpsell
        doWindowUpsell
        RestrictedElectricProvider
        ExtendedRestrictedElectricProvider
        headlineLocation
        keepHeadline
        keepHeadlineInFinalFrame
        headlineAlwaysInFirstFrame
        persistentMessage
        persistentMessage2
        persistentMessageLocation
        showLogos
        homeInsurance
        frameAnimation
        breadCrumbs
        breadCrumbDividerIcon
        noGlobalSignFirstFrame
        submitButton
        firstFrameNextButton
        firstFrameNextButtonHelper
        submitButtonIcon
        nextButton
        nextButtonIcon
        backButton
        backButtonIcon
        progressBar
        progressBarHideFF
        altFFHeadline
        choiceImage
        yearsLeastToGreatest_test
        yearSplit_test
        ipHeadline
        ipSubhead
        dynamicSubheadA
        dynamicSubheadB
        ipSubPattern
        choiceAlphabet
        ipZip
        personalizedFF
        sliders
        dropdown
        dropdownToMultipleChoice
        selectMultipleChoiceOptions
        reverseDropdownLTV
        buttonDisabled
        addressAutoComplete
        submitButtonAnimation
        submitModalAnimation
        allowRedirect
        initialValue
        bgImage
        additionalCashAsEquity
        frontPageAd_test
        showTcpaOutsideForm
        showTcpaAboveButton
        additionalFooterText1
        additionalFooterText2
        additionalFooterText3
        additionalFooterText4
        additionalHeadlineText1
        additionalHeadlineText2
        additionalHeadlineText3
        additionalHeadlineText4
        additionalHeadlineText5
        additionalHeadlineText6
        additionalHeadlineText7
        additionalHeadlineText8
        additionalHeadlineText9
        PoweredByLogo
        additionalFooterBeforeDisclosure
        runZippoFromParam
        showVehicleSubtitle
        companyAddress
        locationOption
        dynamicHeadlineA
        dynamicHeadlineB
        scData {
          siteName
          siteNameV2
          siteVertical
        }
        dynamicSites {
          ipHeadline
          locationOption
          dynamicHeadlineA
          dynamicHeadlineB
          dynamicSurveyName
          persistentMessage
          BlueModoMedia
          persistentMessageLocation
          fontFamily
          fontText
          headline
          subhead
          anuraTrack
          newMedTestDisclosure
          limitedUtils
          gTag
          zipTip
          upsideInsurance
          phonePlaceholder_test
          submitModalAnimation
          keepHeadline
          ipHeadline
          LabelAsPlaceholder
          source
          isSCLabs
          gtmContainer
          useTYPLayout
          showLogos
          homeInsurance
          siteLogo
          interstitialTime
          bgImage
          offerId
          advertisingDisclosure
          additionalFooterText1
          additionalFooterText2
          additionalFooterText3
          additionalFooterText4
          favicon
          scData {
            siteName
          }
          organicLink
          title
        }
        inactiveModal
        propZipFollowUp
        noBack
        noButtons
        noNextButtons
        backButtonNoMultipleChoice
        mobileBackButtonNoMultipleChoice
        noChangeAddress
        firstQuestion
        cashoutPath_test
        blurredRates
        defaultDelta
        countdown
        countdownText
        countdownSubtext
        countdownCloseButton
        countdownHideOnFF
        verifyPage
        useTYPLayout
        pathChoiceOptions {
          label
          value
          bgImage
        }
        propertyTypeOptions {
          label
          value
        }
        setInitialValues {
          title
          value
        }
        finalFrameOptions {
          label
          value
        }
        hiddenFields {
          question
          value
        }
        dynamicAnswers {
          title
          options {
            label
            value
            bgImage
          }
        }
        combinedQuestions {
          title
          questions
        }
      }

      alternateConfig2 {
        title
        poorCreditEject
        offerId
        globalSign
        creditRatingTest
        noWeightAndHeight
        alternateButton
        alternateButtontext
        tyPageShimmer
        sslImage
        fontFamily
        fontText
        stepCount
        noExtraStep_test
        hideStepCountOnFirstFrame
        anuraTrack
        newMedTestDisclosure
        stepOverviewInHeadline
        stepOverviewAboveHeadline
        EstPurchaseDynamicStateSubHeadline
        stepOverview
        step1
        step2
        step3
        step4
        step5
        step6
        cat1
        cat2
        cat3
        cat4
        cat5
        phoneOnSite
        PhonePrePop_test
        fairCreditQuestion
        windowCountTest
        HowThisWorksTitle
        earlyIlxLoad
        ElectricBillButtonsByState
        upsellPhoneTest
        headerBackgroundImage
        ipApiTest
        maMedZones
        maHealthZones
        mediaAlphaQnstSite
        nameAddressCombined_test
        allElectricProviderButtons
        singleValCard
        siteLogo
        interstitialTime
        zipTip
        upsideInsurance
        phonePlaceholder_test
        mobileScrollOnError
        healthBreakOutPii
        LabelAsPlaceholder
        limitedUtils
        logoClickRedirectUrl
        singleValCardReverse
        noPoorCredit
        noPoorCreditAllStates_test
        windowRepairToReplace
        BlueModoMedia
        rateZoneTest
        singleLoanValCard
        validEmailCheckbox
        vehicleDropDown
        upsellProviderButton_test
        buttonsWithParagraph
        acnDropdown
        alternateAcn
        ExtraCashAlt
        ExtraCashAlt_1
        ExtraCashAlt_2_test
        howThisWorks
        howThisWorksPosition
        clickToCallTest
        bdateWithDiv
        gTag
        electricBillButtons
        dynamicWindowsQuestions_test
        MedicareEnrolled_test
        affiliateTestingBan
        homeInsuranceUpsellTest
        zipSubtitle
        gtmContainer
        zipPlaceholder
        tyPageTest
        healthZipTest
        goExperimentID
        manufacturedRedirect
        newsletterCheckbox
        separateEmail
        separateAddress
        njZipCodeTest
        sameCity
        sameCityZip
        showCoverageTypeText
        coverageTypeAltText_test
        privatePolicyCheckbox
        smsDisclosure
        dailyEmailCheckbox
        phoneValidation
        slideAnimation
        customHomePage
        hasSolarEject
        shadeEjection
        emailDisclaimer
        iLeads
        isSolar
        isSCLabs
        tyAnura_Test
        changeAge
        submitButtonDisclosure
        noCongrats
        headline
        rmpSite
        subhead
        skipZip
        skipIncome_test
        dualPageForm
        addReverse
        source
        sourceLocation
        addressSubtext
        singlePageHeader
        doNotRunTCPA
        surveyType
        surveyName
        noPlaceholder
        noEnterOnPlaceholder_test
        noDefaultCheck
        singleHomePage
        advertisingDisclosure
        organicLink
        verifyLink
        tipLocation
        emailLabel
        excellentCreditFF_test
        excellentCreditQuestionTitle_test
        excellentCreditQuestionSubtitle_test
        doSolarUpsell
        doWindowUpsell
        RestrictedElectricProvider
        ExtendedRestrictedElectricProvider
        headlineLocation
        keepHeadline
        keepHeadlineInFinalFrame
        headlineAlwaysInFirstFrame
        persistentMessage
        persistentMessage2
        persistentMessageLocation
        showLogos
        homeInsurance
        frameAnimation
        breadCrumbs
        breadCrumbDividerIcon
        noGlobalSignFirstFrame
        submitButton
        firstFrameNextButton
        firstFrameNextButtonHelper
        submitButtonIcon
        nextButton
        nextButtonIcon
        backButton
        backButtonIcon
        progressBar
        progressBarHideFF
        altFFHeadline
        choiceImage
        yearsLeastToGreatest_test
        yearSplit_test
        ipHeadline
        ipSubhead
        dynamicSubheadA
        dynamicSubheadB
        ipSubPattern
        choiceAlphabet
        ipZip
        personalizedFF
        sliders
        dropdown
        dropdownToMultipleChoice
        selectMultipleChoiceOptions
        reverseDropdownLTV
        buttonDisabled
        addressAutoComplete
        submitButtonAnimation
        submitModalAnimation
        allowRedirect
        initialValue
        bgImage
        additionalCashAsEquity
        frontPageAd_test
        showTcpaOutsideForm
        showTcpaAboveButton
        additionalFooterText1
        additionalFooterText2
        additionalFooterText3
        additionalFooterText4
        additionalHeadlineText1
        additionalHeadlineText2
        additionalHeadlineText3
        additionalHeadlineText4
        additionalHeadlineText5
        additionalHeadlineText6
        additionalHeadlineText7
        additionalHeadlineText8
        additionalHeadlineText9
        PoweredByLogo
        additionalFooterBeforeDisclosure
        runZippoFromParam
        showVehicleSubtitle
        companyAddress
        locationOption
        dynamicHeadlineA
        dynamicHeadlineB
        scData {
          siteName
          siteNameV2
          siteVertical
        }
        dynamicSites {
          ipHeadline
          locationOption
          dynamicHeadlineA
          dynamicHeadlineB
          dynamicSurveyName
          persistentMessage
          persistentMessageLocation
          fontFamily
          submitModalAnimation
          fontText
          BlueModoMedia
          LabelAsPlaceholder
          gTag
          headline
          subhead
          keepHeadline
          anuraTrack
          newMedTestDisclosure
          ipHeadline
          iLeads
          source
          zipTip
          upsideInsurance
          phonePlaceholder_test
          isSCLabs
          gtmContainer
          limitedUtils
          useTYPLayout
          showLogos
          homeInsurance
          siteLogo
          interstitialTime
          bgImage
          offerId
          advertisingDisclosure
          additionalFooterText1
          additionalFooterText2
          additionalFooterText3
          additionalFooterText4
          favicon
          scData {
            siteName
          }
          organicLink
          title
        }
        inactiveModal
        propZipFollowUp
        noBack
        noButtons
        noNextButtons
        backButtonNoMultipleChoice
        mobileBackButtonNoMultipleChoice
        noChangeAddress
        firstQuestion
        cashoutPath_test
        blurredRates
        defaultDelta
        countdown
        countdownText
        countdownSubtext
        countdownCloseButton
        countdownHideOnFF
        verifyPage
        useTYPLayout
        pathChoiceOptions {
          label
          value
          bgImage
        }
        propertyTypeOptions {
          label
          value
        }
        setInitialValues {
          title
          value
        }
        finalFrameOptions {
          label
          value
        }
        hiddenFields {
          question
          value
        }
        dynamicAnswers {
          title
          options {
            label
            value
            bgImage
          }
        }
        combinedQuestions {
          title
          questions
        }
      }
      alternateConfig3 {
        title
        poorCreditEject
        offerId
        globalSign
        creditRatingTest
        noWeightAndHeight
        alternateButton
        alternateButtontext
        sslImage
        fontFamily
        fontText
        stepCount
        tyPageShimmer
        noExtraStep_test
        hideStepCountOnFirstFrame
        anuraTrack
        newMedTestDisclosure
        stepOverviewInHeadline
        stepOverviewAboveHeadline
        allElectricProviderButtons
        EstPurchaseDynamicStateSubHeadline
        stepOverview
        step1
        step2
        step3
        step4
        step5
        step6
        cat1
        cat2
        cat3
        cat4
        cat5
        maMedZones
        maHealthZones
        step1
        step2
        step3
        phoneOnSite
        PhonePrePop_test
        fairCreditQuestion
        windowCountTest
        ElectricBillButtonsByState
        upsellPhoneTest
        healthBreakOutPii
        headerBackgroundImage
        mediaAlphaQnstSite
        nameAddressCombined_test
        singleValCard
        siteLogo
        interstitialTime
        mobileScrollOnError
        zipTip
        ipApiTest
        upsideInsurance
        phonePlaceholder_test
        LabelAsPlaceholder
        limitedUtils
        logoClickRedirectUrl
        singleValCardReverse
        noPoorCredit
        HowThisWorksTitle
        earlyIlxLoad
        noPoorCreditAllStates_test
        windowRepairToReplace
        BlueModoMedia
        rateZoneTest
        singleLoanValCard
        validEmailCheckbox
        vehicleDropDown
        upsellProviderButton_test
        buttonsWithParagraph
        acnDropdown
        alternateAcn
        ExtraCashAlt
        ExtraCashAlt_1
        ExtraCashAlt_2_test
        howThisWorks
        howThisWorksPosition
        clickToCallTest
        bdateWithDiv
        gTag
        electricBillButtons
        dynamicWindowsQuestions_test
        MedicareEnrolled_test
        affiliateTestingBan
        homeInsuranceUpsellTest
        zipSubtitle
        gtmContainer
        zipPlaceholder
        tyPageTest
        healthZipTest
        goExperimentID
        manufacturedRedirect
        newsletterCheckbox
        separateEmail
        separateAddress
        njZipCodeTest
        sameCity
        sameCityZip
        showCoverageTypeText
        coverageTypeAltText_test
        privatePolicyCheckbox
        smsDisclosure
        dailyEmailCheckbox
        phoneValidation
        slideAnimation
        customHomePage
        hasSolarEject
        shadeEjection
        emailDisclaimer
        iLeads
        isSolar
        isSCLabs
        changeAge
        submitButtonDisclosure
        noCongrats
        headline
        rmpSite
        subhead
        skipZip
        skipIncome_test
        dualPageForm
        addReverse
        source
        sourceLocation
        addressSubtext
        singlePageHeader
        doNotRunTCPA
        surveyType
        surveyName
        noPlaceholder
        noEnterOnPlaceholder_test
        noDefaultCheck
        singleHomePage
        advertisingDisclosure
        organicLink
        verifyLink
        tipLocation
        emailLabel
        excellentCreditFF_test
        excellentCreditQuestionTitle_test
        excellentCreditQuestionSubtitle_test
        doSolarUpsell
        doWindowUpsell
        RestrictedElectricProvider
        ExtendedRestrictedElectricProvider
        headlineLocation
        keepHeadline
        keepHeadlineInFinalFrame
        headlineAlwaysInFirstFrame
        persistentMessage
        persistentMessage2
        persistentMessageLocation
        showLogos
        homeInsurance
        frameAnimation
        breadCrumbs
        breadCrumbDividerIcon
        noGlobalSignFirstFrame
        submitButton
        firstFrameNextButton
        firstFrameNextButtonHelper
        submitButtonIcon
        nextButton
        nextButtonIcon
        backButton
        backButtonIcon
        progressBar
        progressBarHideFF
        altFFHeadline
        choiceImage
        yearsLeastToGreatest_test
        yearSplit_test
        ipHeadline
        ipSubhead
        dynamicSubheadA
        dynamicSubheadB
        ipSubPattern
        choiceAlphabet
        ipZip
        personalizedFF
        sliders
        dropdown
        dropdownToMultipleChoice
        selectMultipleChoiceOptions
        reverseDropdownLTV
        buttonDisabled
        addressAutoComplete
        submitButtonAnimation
        submitModalAnimation
        allowRedirect
        initialValue
        bgImage
        additionalCashAsEquity
        frontPageAd_test
        showTcpaOutsideForm
        showTcpaAboveButton
        additionalFooterText1
        additionalFooterText2
        additionalFooterText3
        additionalFooterText4
        additionalHeadlineText1
        additionalHeadlineText2
        additionalHeadlineText3
        additionalHeadlineText4
        additionalHeadlineText5
        additionalHeadlineText6
        additionalHeadlineText7
        additionalHeadlineText8
        additionalHeadlineText9
        PoweredByLogo
        additionalFooterBeforeDisclosure
        runZippoFromParam
        showVehicleSubtitle
        companyAddress
        locationOption
        dynamicHeadlineA
        dynamicHeadlineB
        scData {
          siteName
          siteNameV2
          siteVertical
        }
        dynamicSites {
          ipHeadline
          locationOption
          dynamicHeadlineA
          dynamicHeadlineB
          dynamicSurveyName
          persistentMessage
          persistentMessageLocation
          fontFamily
          submitModalAnimation
          fontText
          BlueModoMedia
          LabelAsPlaceholder
          gTag
          headline
          subhead
          keepHeadline
          anuraTrack
          newMedTestDisclosure
          ipHeadline
          iLeads
          source
          zipTip
          upsideInsurance
          phonePlaceholder_test
          isSCLabs
          gtmContainer
          limitedUtils
          useTYPLayout
          showLogos
          homeInsurance
          siteLogo
          interstitialTime
          bgImage
          offerId
          advertisingDisclosure
          additionalFooterText1
          additionalFooterText2
          additionalFooterText3
          additionalFooterText4
          favicon
          scData {
            siteName
          }
          organicLink
          title
        }
        inactiveModal
        propZipFollowUp
        noBack
        noButtons
        noNextButtons
        backButtonNoMultipleChoice
        mobileBackButtonNoMultipleChoice
        noChangeAddress
        firstQuestion
        cashoutPath_test
        blurredRates
        defaultDelta
        countdown
        countdownText
        countdownSubtext
        countdownCloseButton
        countdownHideOnFF
        verifyPage
        useTYPLayout
        pathChoiceOptions {
          label
          value
          bgImage
        }
        propertyTypeOptions {
          label
          value
        }
        setInitialValues {
          title
          value
        }
        finalFrameOptions {
          label
          value
        }
        hiddenFields {
          question
          value
        }
        dynamicAnswers {
          title
          options {
            label
            value
            bgImage
          }
        }
        combinedQuestions {
          title
          questions
        }
      }

      alternateConfig4 {
        title
        poorCreditEject
        offerId
        globalSign
        creditRatingTest
        noWeightAndHeight
        alternateButton
        alternateButtontext
        tyPageShimmer
        sslImage
        anuraTrack
        newMedTestDisclosure
        fontFamily
        fontText
        stepCount
        noExtraStep_test
        hideStepCountOnFirstFrame
        stepOverviewInHeadline
        noPoorCredit
        maMedZones
        maHealthZones
        noPoorCreditAllStates_test
        homeInsuranceUpsellTest
        stepOverviewAboveHeadline
        EstPurchaseDynamicStateSubHeadline
        stepOverview
        step1
        step2
        step3
        step4
        step5
        step6
        cat1
        cat2
        cat3
        cat4
        cat5
        healthZipTest
        clickToCallTest
        bdateWithDiv
        phoneOnSite
        PhonePrePop_test
        fairCreditQuestion
        windowCountTest
        ElectricBillButtonsByState
        allElectricProviderButtons
        upsellPhoneTest
        BlueModoMedia
        rateZoneTest
        healthBreakOutPii
        LabelAsPlaceholder
        limitedUtils
        singleValCard
        mediaAlphaQnstSite
        nameAddressCombined_test
        ipApiTest
        siteLogo
        interstitialTime
        zipTip
        HowThisWorksTitle
        earlyIlxLoad
        upsideInsurance
        phonePlaceholder_test
        headerBackgroundImage
        logoClickRedirectUrl
        singleValCardReverse
        singleLoanValCard
        validEmailCheckbox
        vehicleDropDown
        buttonsWithParagraph
        acnDropdown
        alternateAcn
        windowRepairToReplace
        ExtraCashAlt
        ExtraCashAlt_2_test
        howThisWorks
        howThisWorksPosition
        gTag
        electricBillButtons
        dynamicWindowsQuestions_test
        MedicareEnrolled_test
        affiliateTestingBan
        zipSubtitle
        gtmContainer
        zipPlaceholder
        manufacturedRedirect
        newsletterCheckbox
        separateEmail
        separateAddress
        njZipCodeTest
        sameCity
        sameCityZip
        showCoverageTypeText
        coverageTypeAltText_test
        privatePolicyCheckbox
        smsDisclosure
        dailyEmailCheckbox
        phoneValidation
        slideAnimation
        customHomePage
        hasSolarEject
        shadeEjection
        emailDisclaimer
        iLeads
        isSolar
        isSCLabs
        changeAge
        submitButtonDisclosure
        noCongrats
        headline
        rmpSite
        subhead
        skipZip
        addReverse
        mobileScrollOnError
        source
        sourceLocation
        addressSubtext
        singlePageHeader
        doNotRunTCPA
        surveyType
        surveyName
        noPlaceholder
        noEnterOnPlaceholder_test
        noDefaultCheck
        singleHomePage
        advertisingDisclosure
        organicLink
        verifyLink
        tipLocation
        emailLabel
        excellentCreditFF_test
        excellentCreditQuestionTitle_test
        excellentCreditQuestionSubtitle_test
        doSolarUpsell
        doWindowUpsell
        RestrictedElectricProvider
        ExtendedRestrictedElectricProvider
        headlineLocation
        keepHeadline
        keepHeadlineInFinalFrame
        headlineAlwaysInFirstFrame
        persistentMessage
        persistentMessage2
        persistentMessageLocation
        showLogos
        homeInsurance
        frameAnimation
        breadCrumbs
        breadCrumbDividerIcon
        noGlobalSignFirstFrame
        submitButton
        firstFrameNextButton
        firstFrameNextButtonHelper
        submitButtonIcon
        nextButton
        nextButtonIcon
        backButton
        backButtonIcon
        progressBar
        progressBarHideFF
        altFFHeadline
        choiceImage
        yearsLeastToGreatest_test
        yearSplit_test
        skipIncome_test
        dualPageForm
        ipHeadline
        tyAnura_Test
        ipSubhead
        dynamicSubheadA
        dynamicSubheadB
        ipSubPattern
        choiceAlphabet
        ipZip
        personalizedFF
        sliders
        dropdown
        dropdownToMultipleChoice
        selectMultipleChoiceOptions
        reverseDropdownLTV
        buttonDisabled
        addressAutoComplete
        submitButtonAnimation
        submitModalAnimation
        allowRedirect
        initialValue
        bgImage
        additionalCashAsEquity
        frontPageAd_test
        showTcpaOutsideForm
        showTcpaAboveButton
        additionalFooterText1
        additionalFooterText2
        additionalFooterText3
        additionalFooterText4
        additionalHeadlineText1
        additionalHeadlineText2
        additionalHeadlineText3
        additionalHeadlineText4
        additionalHeadlineText5
        additionalHeadlineText6
        additionalHeadlineText7
        additionalHeadlineText8
        additionalHeadlineText9
        PoweredByLogo
        additionalFooterBeforeDisclosure
        runZippoFromParam
        showVehicleSubtitle
        companyAddress
        locationOption
        dynamicHeadlineA
        dynamicHeadlineB
        scData {
          siteName
          siteNameV2
          siteVertical
        }
        dynamicSites {
          ipHeadline
          locationOption
          dynamicHeadlineA
          dynamicHeadlineB
          dynamicSurveyName
          persistentMessage
          persistentMessageLocation
          fontFamily
          fontText
          headline
          submitModalAnimation
          subhead
          anuraTrack
          newMedTestDisclosure
          keepHeadline
          BlueModoMedia
          LabelAsPlaceholder
          limitedUtils
          ipHeadline
          source
          zipTip
          upsideInsurance
          phonePlaceholder_test
          isSCLabs
          gtmContainer
          useTYPLayout
          showLogos
          homeInsurance
          siteLogo
          interstitialTime
          bgImage
          gTag
          offerId
          advertisingDisclosure
          additionalFooterText1
          additionalFooterText2
          additionalFooterText3
          additionalFooterText4
          favicon
          scData {
            siteName
          }
          organicLink
          title
        }
        inactiveModal
        propZipFollowUp
        noBack
        noButtons
        noNextButtons
        backButtonNoMultipleChoice
        mobileBackButtonNoMultipleChoice
        noChangeAddress
        firstQuestion
        cashoutPath_test
        blurredRates
        defaultDelta
        countdown
        countdownText
        countdownSubtext
        countdownCloseButton
        countdownHideOnFF
        verifyPage
        useTYPLayout
        pathChoiceOptions {
          label
          value
          bgImage
        }
        propertyTypeOptions {
          label
          value
        }
        setInitialValues {
          title
          value
        }
        finalFrameOptions {
          label
          value
        }
        hiddenFields {
          question
          value
        }
        dynamicAnswers {
          title
          options {
            label
            value
            bgImage
          }
        }
        combinedQuestions {
          title
          questions
        }
      }

      backgroundImage: imageSharp(
        fluid: { originalName: { in: ["bg.jpg", "bg.png"] } }
      ) {
        fluid(maxWidth: 2200) {
          ...GatsbyImageSharpFluid
        }
      }
      siteLogo: imageSharp(
        fluid: { originalName: { in: ["logo.jpg", "logo.png"] } }
      ) {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
      # siteImages: allFile(
      #   filter: { extension: { in: ["jpg", "png", "svg"] } }
      # ) {
      #   edges {
      #     node {
      #       name
      #       childImageSharp {
      #         fluid {
      #           ...GatsbyImageSharpFluid_tracedSVG
      #         }
      #       }
      #     }
      #   }
      siteImages: allImageSharp {
        edges {
          node {
            id
            fluid {
              originalName
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
      bannerImages: allImageSharp {
        edges {
          node {
            id
            fluid(maxWidth: 2200) {
              originalName
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }

      interestRates: file(relativePath: { eq: "interestRates.txt" }) {
        childPlainText {
          content
        }
      }
      rmpDns: text(name: { eq: "rmpDns" }) {
        content
        name
      }
      rmpPrivacyText: text(name: { eq: "rmpPrivacyText" }) {
        content
        name
      }
      rmpTosText: text(name: { eq: "rmpTosText" }) {
        content
        name
      }
      rmpLicensure: text(name: { eq: "rmpLicensure" }) {
        content
        name
      }

      accessibilityStatement: file(
        relativePath: { eq: "accessibilityStatement.txt" }
      ) {
        childPlainText {
          content
        }
      }
      smsToC: file(relativePath: { eq: "smsToC.txt" }) {
        childPlainText {
          content
        }
      }
      smsPP: file(relativePath: { eq: "smsPP.txt" }) {
        childPlainText {
          content
        }
      }
      thankyouRmpTos: file(relativePath: { eq: "rmpTosText.txt" }) {
        childPlainText {
          content
        }
      }
      thankyouRmpPrivacy: file(relativePath: { eq: "rmpPrivacyText.txt" }) {
        childPlainText {
          content
        }
      }
      thankyouRmpDns: file(relativePath: { eq: "rmpDns.txt" }) {
        childPlainText {
          content
        }
      }
      thankyouRmpLicensure: file(relativePath: { eq: "rmpLicensure.txt" }) {
        childPlainText {
          content
        }
      }
      thankyouGhTos: file(relativePath: { eq: "ghTos.txt" }) {
        childPlainText {
          content
        }
      }
      thankyouGhPrivacy: file(relativePath: { eq: "ghPrivacy.txt" }) {
        childPlainText {
          content
        }
      }
      thankyouGhDns: file(relativePath: { eq: "ghDns.txt" }) {
        childPlainText {
          content
        }
      }
      solarPrivacy: file(relativePath: { eq: "solarPrivacy.txt" }) {
        childPlainText {
          content
        }
      }
      vaFAQ: file(relativePath: { eq: "vaFAQ.txt" }) {
        childPlainText {
          content
        }
      }
    }
  `);

  return {
    config: data?.config,
    alternateConfig1: data?.alternateConfig1,
    alternateConfig2: data?.alternateConfig2,
    alternateConfig3: data?.alternateConfig3,
    alternateConfig4: data?.alternateConfig4,
    backgroundImage: data?.backgroundImage,
    siteLogo: data?.siteLogo,
    siteImages: data?.siteImages,
    bannerImages: data?.bannerImages,
    globalSign: data?.globalSign,
    interestRates: data?.interestRates,
    rmpDns: data?.rmpDns,
    rmpPrivacyText: data?.rmpPrivacyText,
    rmpTosText: data?.rmpTosText,
    rmpLicensure: data?.thankyouRmpLicensure,
    accessibilityStatement: data?.accessibilityStatement,
    smsToC: data?.smsToC,
    smsPP: data?.smsPP,
    thankyouRmpTos: data?.thankyouRmpTos,
    thankyouRmpPrivacy: data?.thankyouRmpPrivacy,
    thankyouRmpDns: data?.thankyouRmpDns,
    thankyouRmpLicensure: data?.thankyouRmpLicensure,
    thankyouGhTos: data?.thankyouGhTos,
    thankyouGhPrivacy: data?.thankyouGhPrivacy,
    thankyouGhDns: data?.thankyouGhDns,
    solarPrivacy: data?.solarPrivacy,
    vaFAQ: data?.vaFAQ,
  };
};
