import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import useMouse from '@react-hook/mouse-position';
import styled from 'styled-components';
import Img from 'gatsby-image';
import styles from '../utils/customStyles';
import ProgressBar from './ProgressBar/ProgressBar';
import GlobalSign from './GlobalSign';
import Headline from './Dynamic/Headline';
import AbandonModal from './Dynamic/AbandonModal';
import { StoreContext } from './StoreContext';
import usePrevious from '../hooks/usePrevious';
import { g4EventTracking } from '../utils/dynamic/g4Tracking';
import { webHookTrack } from '../utils/dynamic/webhookTrack';

const customStyles = styles();

const HeaderContainer = styled.header`
  max-width: 100%;
  ${customStyles.HeaderContainer}
  a {
    width: 100%;
  }
`;

const HeaderImage = styled(Img)`
  width: 100%;
  ${customStyles.HeaderImage}
  img {
    object-fit: contain !important;
  }
`;

const TipContainer = styled.p`
  ${customStyles.TipContainer}
`;

const StyledA = styled.a`
  ${customStyles.HeaderImage}
  margin-bottom: 10px;
`;

const Header = ({
  config,
  activeTip,
  siteLogo,
  globalImage,
  sslIcon,
  findSiteLogo,
  hideHeadlineTYP = false,
  versionUpdate = false,
  tyHeader = '',
}) => {
  const {
    title,
    progressBar,
    globalSign,
    sslImage,
    tipLocation,
    headlineLocation,
    keepHeadline,
    keepHeadlineInFinalFrame,
    logoClickRedirectUrl,
    surveyName,
    PoweredByLogo,
  } = config;

  const { headline, params, page, quizLength } = useContext(StoreContext);
  const previousConfig = usePrevious(config.siteLogo);

  const ref = useRef(null);
  const mouse = useMouse(ref, {
    enterDelay: 100,
    leaveDelay: 100,
  });

  const [showAbandon, setShowAbandon] = useState(false);

  const progressTypes = ['circle', 'bar-header'];

  useEffect(() => {
    if (config && previousConfig) {
      if (config.siteLogo !== 'logo.png') {
        findSiteLogo(config.siteLogo);
      }
      if (previousConfig !== config.siteLogo) {
        try {
          findSiteLogo(config.siteLogo);
        } catch (error) {
          console.log('Error triggering findSiteLogo()', error);
        }
      }

      // if (versionUpdate && config.dynamicSites?.length > 0) {
      //   findSiteLogo(config.dynamicSites[0].siteLogo);
      // }
    }
  }, [config, findSiteLogo, previousConfig, versionUpdate]);

  let displayHeadline = false;
  if (headlineLocation === 'header') {
    if (keepHeadline && page !== quizLength - 1) {
      displayHeadline = true;
    } else if (keepHeadlineInFinalFrame) {
      displayHeadline = true;
    } else if (!page) {
      displayHeadline = true;
    }
  }

  // Used on TYP's that use the LayoutTYP component. Prevents headline from rendering there.
  if (hideHeadlineTYP) {
    displayHeadline = false;
  }

  const handleClick = () => {
    webHookTrack('misc', {
      logoClick: logoClickRedirectUrl,
    });

    g4EventTracking('misc', { logoClick: logoClickRedirectUrl });
  };

  return (
    <HeaderContainer id="header" ref={ref} className={tyHeader}>
      {/* eslint-disable-next-line */}
      {logoClickRedirectUrl ? (
        <StyledA onClick={handleClick} href={`https://${logoClickRedirectUrl}`}>
          <HeaderImage
            id="header-image"
            data-testid="header-image"
            fluid={siteLogo}
            className="siteLogo"
            alt={`${surveyName} logo`}
          />
        </StyledA>
      ) : siteLogo ? (
        <HeaderImage
          id="header-image"
          data-testid="header-image"
          fluid={siteLogo}
          className="siteLogo"
          alt={`${surveyName} logo`}
        />
      ) : null}

      {displayHeadline && (
        <Headline html={headline} config={config} params={params} />
      )}
      {PoweredByLogo && (
        <div className={`PoweredByContainer powered-${page}`}>
          <p>Powered By:</p>
          <div className="poweredLogo" />
        </div>
      )}
      {globalSign === 'header' && (
        <GlobalSign
          globalImage={globalImage}
          altText="Secure GlobalSign Logo"
        />
      )}
      {sslImage === 'header' && (
        <GlobalSign
          globalImage={sslIcon}
          altText="Secure SSL Encryption Icon"
        />
      )}

      {progressTypes.includes(progressBar) && (
        <ProgressBar config={config} type={progressBar} />
      )}
      {tipLocation === 'header' && activeTip && (
        <TipContainer id={`tip-container-${page}`}>
          {activeTip.content}
        </TipContainer>
      )}
      {showAbandon && config.showAbandonerPopup && (
        <AbandonModal
          config={config}
          setShowAbandon={setShowAbandon}
          mouse={mouse}
        />
      )}
      {config.surveyName === `mortgage.savingspro.net` && (
        <a href="tel:9182019990">
          <p className="mortgageNumber">918-201-9990</p>
        </a>
      )}
    </HeaderContainer>
  );
};

Header.propTypes = {
  config: PropTypes.object.isRequired,
  activeTip: PropTypes.object,
  siteLogo: PropTypes.object,
  globalImage: PropTypes.object,
  sslIcon: PropTypes.object,
  findSiteLogo: PropTypes.func,
  hideHeadlineTYP: PropTypes.bool,
  versionUpdate: PropTypes.bool,
};

const MemoizedHeader = React.memo(Header);
export default MemoizedHeader;
