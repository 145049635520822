import React, { useState } from 'react';
import PropTypes from 'prop-types';

const initialState = {
  page: 1,
  quizLength: 0,
  order: [],
  params: {},
  activeTip: {},
  headline: [],
  documentTitle: '',
  mortgagePercent: 0,
  mortgageFill: 0,
  hideHeadline: false,
  config: {},
  images: [],
  isZipValid: '',
  values: {},
  questionOrder: {},
  globalValues: {},
  singlePageValidated: false,
  urlPrepopValues: {},
};

export const StoreContext = React.createContext({ ...initialState });

const StoreProvider = ({ children }) => {
  const [page, setPage] = useState(0);
  const [quizLength, setQuizLength] = useState(null);
  const [order, setOrder] = useState([]);
  const [params, setParams] = useState({});
  const [disclosure, setDisclosure] = useState({});
  const [loanPurpose, setLoanPurpose] = useState('');
  const [singlePageQuestions, setSinglePageQuestions] = useState('');
  const [activeTip, setTip] = useState({});
  const [headline, setHeadline] = useState([]);
  const [documentTitle, setDocumentTitle] = useState('');
  const [mortgagePercent, setMortgagePercent] = useState('');
  const [mortgageFill, setMortgageFill] = useState('');
  const [hideHeadline, setHideHeadline] = useState(false);
  const [configContext, setConfigContext] = useState({});
  const [images, setImages] = useState([]);
  const [isZipValid, setIsZipValid] = useState('');
  const [values, setValues] = useState({});
  const [questionOrder, setQuestionOrder] = useState({});
  const [globalValues, setGlobalValues] = useState({});
  const [singlePageValidated, setSinglePageValidated] = useState(false);
  const [urlPrepopValues, setURLPrepopValues] = useState({});
  const [inactiveModal, setInactiveModal] = useState(false);
  const [contextBgImage, setContextBgImage] = useState(false);
  const [contextZipHeadline, setContextZipHeadline] = useState(false);
  const [tcpaDisplayed, setTcpaDisplayed] = useState(false);
  const [utilButtons, setUtilButtons] = useState(false);
  const [experimentID, setExperimentID] = useState('');
  const [healthTitleClicked, setHealthTitleClicked] = useState('');

  return (
    <StoreContext.Provider
      value={{
        healthTitleClicked,
        setHealthTitleClicked,
        page,
        setPage,
        quizLength,
        setQuizLength,
        order,
        setOrder,
        params,
        setParams,
        disclosure,
        setDisclosure,
        loanPurpose,
        setLoanPurpose,
        singlePageQuestions,
        setSinglePageQuestions,
        activeTip,
        setTip,
        headline,
        setHeadline,
        documentTitle,
        setDocumentTitle,
        mortgagePercent,
        setMortgagePercent,
        mortgageFill,
        setMortgageFill,
        hideHeadline,
        setHideHeadline,
        configContext,
        setConfigContext,
        images,
        setImages,
        isZipValid,
        setIsZipValid,
        globalValues,
        setGlobalValues,
        questionOrder,
        setQuestionOrder,
        values,
        setValues,
        singlePageValidated,
        setSinglePageValidated,
        urlPrepopValues,
        setURLPrepopValues,
        inactiveModal,
        setInactiveModal,
        contextBgImage,
        setContextBgImage,
        contextZipHeadline,
        setContextZipHeadline,
        tcpaDisplayed,
        setTcpaDisplayed,
        utilButtons,
        setUtilButtons,
        setExperimentID,
        experimentID,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

StoreProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default StoreProvider;
