/* eslint-disable */
const React = require('react');
const ILX = require('./src/components/ThankyouPages/ILX').default;
const { trustedForm } = require('./src/utils/dynamic/trustedForm');
require('whatwg-fetch');
require('abortcontroller-polyfill');
require('babel-polyfill');

exports.onClientEntry = () => {
  // polyfill for IE
  const fetchPolyfill = window.fetch;
  if (fetchPolyfill) {
    require('proxy-polyfill');

    const AbortController = window.AbortController;
    const controller = new AbortController();
    const signal = controller.signal;

    function observe(o, callback) {
      return new Proxy(o, {
        set(target, property, value) {
          callback(property, value);
          target[property] = value;
        },
      });
    }

    const x = { name: 'BB-8' };
    const p = observe(x, (property, value) => {});
    p.name = 'BB-9';
  } else {
    const fetch = window.fetch;

    const AbortController = window.AbortController;
    const controller = new AbortController();
    const signal = controller.signal;
  }
};

exports.onInitialClientRender = () => {
  trustedForm();
};

exports.wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <>
      {element}
      <ILX {...props} />
    </>
  );
};
