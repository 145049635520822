import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import styles from '../../utils/customStyles';

const customStyles = styles();

const StepOverviewContainer = styled.section`
  ${customStyles.StepOverviewContainer}
`;

const OverviewDetails = styled.div`
  position: relative;
  p {
    margin-top: 10px;
  }
  ${customStyles.OverviewDetails}
`;
const OverviewDetailsDiv = styled.div`
  ${customStyles.OverviewDetails}
`;

const OverviewDot = styled.div`
  width: 15px;
  height: 15px;
  margin: 3px 0;
  border-radius: 50%;
  background: #fff;
  .svgContainer {
    display: none;
  }
  @media (max-width: 801px) {
    .svgContainer {
      display: none;
      .complete & {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 8px;
        height: 8px;
        svg {
          width: 100%;
        }
      }
    }
  }
  ${customStyles.OverviewDot}
`;

const OverviewNumber = styled.span`
  z-index: 1;
  ${customStyles.OverviewNumber}
`;
const ConnectorLine = styled.div`
  position: absolute;
  top: 15px;
  left: -168px;
  width: 200px;
  height: 10px;
  background: #fff;
  z-index: 0;
  ${customStyles.ConnectorLine}
`;

export const StepOverview = ({ page, isLastPage, step1, step2, step3 }) => (
  <StepOverviewContainer>
    <OverviewDetails isHighlighted>
      <OverviewNumber>1</OverviewNumber>
      {step1}
    </OverviewDetails>
    <OverviewDetails isHighlighted={page}>
      <OverviewNumber>2</OverviewNumber>
      {step2}
    </OverviewDetails>
    <OverviewDetails isHighlighted={isLastPage}>
      <OverviewNumber>3</OverviewNumber>
      {step3}
    </OverviewDetails>
  </StepOverviewContainer>
);

export const StepOverview2 = ({ page, isLastPage, step1, step2, step3 }) => {
  return (
    <StepOverviewContainer>
      <OverviewDetails isHighlighted={page === 0}>
        <OverviewNumber isHighlighted={page === 0}>1</OverviewNumber>
        <span className="text">{step1}</span>
      </OverviewDetails>
      <OverviewDetails isHighlighted={page && !isLastPage}>
        <OverviewNumber isHighlighted={page && !isLastPage}>2</OverviewNumber>
        <span className="text">{step2}</span>
      </OverviewDetails>
      <OverviewDetails isHighlighted={isLastPage}>
        <OverviewNumber isHighlighted={isLastPage}>3</OverviewNumber>
        <span className="text">{step3}</span>
      </OverviewDetails>
    </StepOverviewContainer>
  );
};

export const StepOverview3 = ({ page, isLastPage, step1, step2, step3 }) => (
  <StepOverviewContainer>
    <OverviewDetails isHighlighted>
      <OverviewNumber className={`${page !== 0 ? 'complete' : ''}`}>
        &#x2713;
      </OverviewNumber>
      <p>{step1}</p>
    </OverviewDetails>
    <OverviewDetails isHighlighted={page}>
      <OverviewNumber>&#x2713;</OverviewNumber>
      <p>{step2}</p>
      <ConnectorLine className={`${page !== 0 ? 'completedStep' : ''}`} />
    </OverviewDetails>
    <OverviewDetails isHighlighted={isLastPage}>
      <OverviewNumber>&#x2713;</OverviewNumber>
      <p>{step3}</p>
      <ConnectorLine className={`${isLastPage ? 'completedStep' : ''}`} />
    </OverviewDetails>
  </StepOverviewContainer>
);
export const StepOverviewVert = ({
  isLastPage,
  currQuestion,
  page,
  cat1,
  cat2,
  cat3,
  cat4,
  step1,
  step2,
  step3,
  step4,
  step5,
  step6,
}) => {
  const step2CompleteArray = cat1.concat(cat2, cat3, cat4);
  const step3CompleteArray = cat2.concat(cat3, cat4);
  const step4CompleteArray = cat3.concat(cat4);
  let step2Complete = false;
  let step3Complete = false;
  let step4Complete = false;
  let step5Complete = false;

  if (step2CompleteArray.includes(currQuestion)) {
    step2Complete = true;
  } else {
    step2Complete = false;
  }
  if (step3CompleteArray.includes(currQuestion)) {
    step3Complete = true;
  } else {
    step3Complete = false;
  }
  if (step4CompleteArray.includes(currQuestion)) {
    step4Complete = true;
  } else {
    step4Complete = false;
  }
  if (cat4.includes(currQuestion)) {
    step5Complete = true;
  } else {
    step5Complete = false;
  }
  if (isLastPage) {
    step2Complete = true;
    step3Complete = true;
    step4Complete = true;
    step5Complete = true;
  }

  return (
    <StepOverviewContainer className={`step${page} vertical`}>
      <OverviewDetails
        isHighlighted={page === 0}
        id="step1"
        className="complete"
      >
        <OverviewDot isHighlighted={page === 0} />
        <p>{step1}</p>
      </OverviewDetails>
      <OverviewDetails
        isHighlighted={cat1.includes(currQuestion)}
        className={step2Complete ? 'complete' : ''}
      >
        <ConnectorLine />
        <OverviewDot isHighlighted={cat1.includes(currQuestion)} />
        <p>{step2}</p>
      </OverviewDetails>
      <OverviewDetails
        isHighlighted={cat2.includes(currQuestion)}
        className={step3Complete ? 'complete' : ''}
      >
        <ConnectorLine />
        <OverviewDot isHighlighted={cat2.includes(currQuestion)} />
        <p>{step3}</p>
      </OverviewDetails>
      <OverviewDetails
        isHighlighted={cat3.includes(currQuestion)}
        className={step4Complete ? 'complete' : ''}
      >
        <ConnectorLine />
        <OverviewDot isHighlighted={cat3.includes(currQuestion)} />
        <p>{step4}</p>
      </OverviewDetails>
      <OverviewDetails
        isHighlighted={cat4.includes(currQuestion)}
        className={step5Complete ? 'complete' : ''}
      >
        <ConnectorLine />
        <OverviewDot isHighlighted={cat4.includes(currQuestion)} />
        <p>{step5}</p>
      </OverviewDetails>
      <OverviewDetails isHighlighted={isLastPage} id="step6">
        <ConnectorLine isHighlighted={page} />
        <OverviewDot isHighlighted={isLastPage} />
        <p>{step6}</p>
      </OverviewDetails>
    </StepOverviewContainer>
  );
};
export const StepOverviewFiveStep = ({
  isLastPage,
  currQuestion,
  page,
  cat1,
  cat2,
  cat3,
  step1,
  step2,
  step3,
  step4,
  step5,
}) => {
  const step2CompleteArray = cat1.concat(cat2, cat3);
  const step3CompleteArray = cat2.concat(cat3);
  const step4CompleteArray = cat3;

  let step2Complete = false;
  let step3Complete = false;
  let step4Complete = false;

  if (step2CompleteArray.includes(currQuestion)) {
    step2Complete = true;
  } else {
    step2Complete = false;
  }
  if (step3CompleteArray.includes(currQuestion)) {
    step3Complete = true;
  } else {
    step3Complete = false;
  }
  if (step4CompleteArray.includes(currQuestion)) {
    step4Complete = true;
  } else {
    step4Complete = false;
  }

  if (isLastPage) {
    step2Complete = true;
    step3Complete = true;
    step4Complete = true;
  }

  return (
    <StepOverviewContainer className={`step${page}`}>
      <OverviewDetails
        isHighlighted={page === 0}
        id="step1"
        className="complete"
      >
        <OverviewDot isHighlighted={page === 0}>
          <div className="svgContainer">
            <svg
              viewBox="0 0 34 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.2426 22.6274L11.3137 26.5563L0 15.2426L4.24261 11L11.3137 18.071L29.3848 0L33.6274 4.24261L15.5563 22.3137L15.2426 22.6274Z"
                fill="#4A803E"
              />
            </svg>
          </div>
        </OverviewDot>
        <p>{step1}</p>
      </OverviewDetails>
      <OverviewDetails
        isHighlighted={cat1.includes(currQuestion)}
        className={step2Complete ? 'complete' : ''}
        id="step2"
      >
        <ConnectorLine
          isHighlighted={cat1.includes(currQuestion)}
          className={step2Complete ? 'complete' : ''}
        />
        <OverviewDot isHighlighted={cat1.includes(currQuestion)}>
          <div className="svgContainer">
            <svg
              viewBox="0 0 34 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.2426 22.6274L11.3137 26.5563L0 15.2426L4.24261 11L11.3137 18.071L29.3848 0L33.6274 4.24261L15.5563 22.3137L15.2426 22.6274Z"
                fill="#4A803E"
              />
            </svg>
          </div>
        </OverviewDot>
        <p>{step2}</p>
      </OverviewDetails>
      <OverviewDetails
        isHighlighted={cat2.includes(currQuestion)}
        className={step3Complete ? 'complete' : ''}
        id="step3"
      >
        <ConnectorLine
          isHighlighted={cat2.includes(currQuestion)}
          className={step3Complete ? 'complete' : ''}
        />
        <OverviewDot isHighlighted={cat2.includes(currQuestion)}>
          <div className="svgContainer">
            <svg
              viewBox="0 0 34 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.2426 22.6274L11.3137 26.5563L0 15.2426L4.24261 11L11.3137 18.071L29.3848 0L33.6274 4.24261L15.5563 22.3137L15.2426 22.6274Z"
                fill="#4A803E"
              />
            </svg>
          </div>
        </OverviewDot>
        <p>{step3}</p>
      </OverviewDetails>
      <OverviewDetails
        isHighlighted={cat3.includes(currQuestion)}
        className={step4Complete ? 'complete' : ''}
        id="step4"
      >
        <ConnectorLine
          isHighlighted={cat3.includes(currQuestion)}
          className={step4Complete ? 'complete' : ''}
        />
        <OverviewDot isHighlighted={cat3.includes(currQuestion)}>
          <div className="svgContainer">
            <svg
              viewBox="0 0 34 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.2426 22.6274L11.3137 26.5563L0 15.2426L4.24261 11L11.3137 18.071L29.3848 0L33.6274 4.24261L15.5563 22.3137L15.2426 22.6274Z"
                fill="#4A803E"
              />
            </svg>
          </div>
        </OverviewDot>
        <p>{step4}</p>
      </OverviewDetails>

      <OverviewDetails
        isHighlighted={isLastPage}
        className={isLastPage ? 'complete' : ''}
        id="step5"
      >
        <ConnectorLine
          isHighlighted={isLastPage}
          className={isLastPage ? 'complete' : ''}
        />
        <OverviewDot isHighlighted={isLastPage} />
        <p>{step5}</p>
      </OverviewDetails>
    </StepOverviewContainer>
  );
};
export const StepOverviewFourStep = ({
  isLastPage,
  currQuestion,
  page,
  cat1,
  cat2,
  cat3,
  step1,
  step2,
  step3,
  step4,
  step5,
}) => {
  const step2CompleteArray = cat1.concat(cat2, cat3);
  const step3CompleteArray = cat2.concat(cat3);

  let step2Complete = false;
  let step3Complete = false;

  if (step2CompleteArray.includes(currQuestion)) {
    step2Complete = true;
  } else {
    step2Complete = false;
  }
  if (step3CompleteArray.includes(currQuestion)) {
    step3Complete = true;
  } else {
    step3Complete = false;
  }

  if (isLastPage) {
    step2Complete = true;
    step3Complete = true;
  }

  return (
    <StepOverviewContainer className={`step${page} vertical`}>
      <OverviewDetails
        isHighlighted={page === 0}
        id="step1"
        className="complete"
      >
        <OverviewDot isHighlighted={page === 0}>
          <div className="svgContainer">
            <svg
              viewBox="0 0 34 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.2426 22.6274L11.3137 26.5563L0 15.2426L4.24261 11L11.3137 18.071L29.3848 0L33.6274 4.24261L15.5563 22.3137L15.2426 22.6274Z"
                fill="#4A803E"
              />
            </svg>
          </div>
        </OverviewDot>
        <p>{step1}</p>
      </OverviewDetails>
      <OverviewDetails
        isHighlighted={cat1.includes(currQuestion)}
        className={step2Complete ? 'complete' : ''}
      >
        <ConnectorLine
          isHighlighted={cat1.includes(currQuestion)}
          className={step2Complete ? 'complete' : ''}
        />
        <OverviewDot isHighlighted={cat1.includes(currQuestion)}>
          <div className="svgContainer">
            <svg
              viewBox="0 0 34 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.2426 22.6274L11.3137 26.5563L0 15.2426L4.24261 11L11.3137 18.071L29.3848 0L33.6274 4.24261L15.5563 22.3137L15.2426 22.6274Z"
                fill="#4A803E"
              />
            </svg>
          </div>
        </OverviewDot>
        <p>{step2}</p>
      </OverviewDetails>
      <OverviewDetails
        isHighlighted={cat2.includes(currQuestion)}
        className={step3Complete ? 'complete' : ''}
      >
        <ConnectorLine
          isHighlighted={cat2.includes(currQuestion)}
          className={step3Complete ? 'complete' : ''}
        />
        <OverviewDot isHighlighted={cat2.includes(currQuestion)}>
          <div className="svgContainer">
            <svg
              viewBox="0 0 34 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.2426 22.6274L11.3137 26.5563L0 15.2426L4.24261 11L11.3137 18.071L29.3848 0L33.6274 4.24261L15.5563 22.3137L15.2426 22.6274Z"
                fill="#4A803E"
              />
            </svg>
          </div>
        </OverviewDot>
        <p>{step3}</p>
      </OverviewDetails>

      <OverviewDetails isHighlighted={isLastPage} id="step4">
        <ConnectorLine
          isHighlighted={isLastPage}
          className={isLastPage ? 'complete' : ''}
        />
        <OverviewDot isHighlighted={isLastPage} />
        <p>{step4}</p>
      </OverviewDetails>
    </StepOverviewContainer>
  );
};

StepOverview.propTypes = {
  page: PropTypes.number,
  isLastPage: PropTypes.bool,
  step1: PropTypes.string,
  step2: PropTypes.string,
  step3: PropTypes.string,
};
StepOverview2.propTypes = {
  page: PropTypes.number,
  isLastPage: PropTypes.bool,
  step1: PropTypes.string,
  step2: PropTypes.string,
  step3: PropTypes.string,
};
StepOverview3.propTypes = {
  page: PropTypes.number,
  isLastPage: PropTypes.bool,
  step1: PropTypes.string,
  step2: PropTypes.string,
  step3: PropTypes.string,
};
StepOverviewVert.propTypes = {
  page: PropTypes.number,
  isLastPage: PropTypes.bool,
  currQuestion: PropTypes.string,
  cat1: PropTypes.array,
  cat2: PropTypes.array,
  cat3: PropTypes.array,
  cat4: PropTypes.array,
  step1: PropTypes.string,
  step2: PropTypes.string,
  step3: PropTypes.string,
  step4: PropTypes.string,
  step5: PropTypes.string,
  step6: PropTypes.string,
};
StepOverviewFiveStep.propTypes = {
  page: PropTypes.number,
  isLastPage: PropTypes.bool,
  currQuestion: PropTypes.string,
  cat1: PropTypes.array,
  cat2: PropTypes.array,
  cat3: PropTypes.array,
  step1: PropTypes.string,
  step2: PropTypes.string,
  step3: PropTypes.string,
  step4: PropTypes.string,
  step5: PropTypes.string,
};
