import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import styled from 'styled-components';
import { useLayoutQueries } from '../../utils/queries';
import { webHookTrack } from '../../utils/dynamic/webhookTrack';
import storage from '../../utils/storage';
import TyHeader from '../Dynamic/CustomTyHeader';
import LayoutTYP from '../Containers/LayoutTYP';
import { loadScript } from '../../utils/loadScript';
import './adButlerStyles.css';
import { g4EventTracking } from '../../utils/dynamic/g4Tracking';

// default position of this container is static. Make sure that we always have it as relative and a z-index of 1 so that up-sells can show over the zones
const Container = styled.div`
  display: ${props => (props.path !== '/' && props.loadIlx ? 'flex' : 'none')};
  flex-direction: column;
  margin: 0 auto;
  padding: 0;
  min-height: 100vh;
  width: 100%;
  max-width: 1200px;
  font-family: sans-serif;
  background: #ffffff;
  position: relative;
  z-index: 1;

  @media (max-width: 600px) {
    width: 100%;
  }
`;
const CredibleDisclosureContainer = styled.p`
  color: rgb(118, 118, 118);
  font-size: 12px;
  line-height: 16px;
  width: 100%;
  text-align: center;
  padding-top: 15px;
  flex: 0 0 50px;
  margin-top: auto;
  margin-bottom: 10px;
  .srOnly {
    position: absolute;
    top: -9999px;
    left: -9999px;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
  @media (max-width: 860px) {
    position: relative;
  }
`;

const ILX = ({ path }) => {
  const [tyLoaded, setTyLoaded] = useState('');
  const [ILXZonesLoaded, setZonesLoaded] = useState('');
  const [loadIlx, setLoadIlx] = useState(false);
  const [updatedConfig, setUpdatedConfig] = useState({});

  const {
    config,
    bannerImages,
    siteImages,
    alternateConfig1,
    alternateConfig2,
    alternateConfig3,
    alternateConfig4,
  } = useLayoutQueries();

  // used to determine if we should render ILX when going straight to the ty page
  const IlxDisplay = [
    'fin_autorefi',
    'hs_solar',
    'hs_roofing',
    'hs_window',
    'hs_br',
    'harp',
    'va',
    '100k',
    'dpa',
    'fha',
    'generic',
    'defaultBadCredit',
    'other',
  ];

  const determineTyPath = route => {
    if (
      route.includes('ty') ||
      route.includes('thankyouBCP') ||
      route.includes('usl-thankyou') ||
      route.includes('tel-thankyou') ||
      route.includes('shi-thankyou') ||
      route.includes('ermna-thankyou') ||
      route.includes('emoddu-thankyou')
    ) {
      return true;
      // eslint-disable-next-line no-else-return
    } else {
      return false;
    }
  };

  const determineShowRatePath = (route, params) => {
    // please note that you will need to make updates in rate.js and nextpage.js as well
    if (route.includes('rate')) {
      if (config.isSCLabs) return false;

      if (
        // roofing should always show ILX
        config.scData.siteVertical === 'hs_roofing' ||
        // Always so ILX zones for dpa
        config.scData.siteVertical === 'dpa' ||
        // renter ejection for solar but solar ejection show adbutler still
        (config.scData.siteVertical === 'hs_solar' && !params.HasSolar) ||
        // test for window renter ejection
        (config.scData.siteVertical === 'hs_window' && params.zipRedirect) ||
        (config.scData.siteVertical === 'hs_br' && params.version === '4') ||
        config.surveyName === 'mortgage.ebridgefinancial.com'
      ) {
        return true;
      }
      return false;
    }
    return false;
  };
  // make sure that interLincx never has anything rendered on the landing page
  useEffect(() => {
    const interLincxTarget = document.getElementById('interLincxTarget');
    if (path === '/' && interLincxTarget) {
      interLincxTarget.innerHTML = '';
      setLoadIlx(false);
    }
  }, [path]);

  // this useEffect is for loading zones if user goes to ty page directly. Please do not add or take anything away from it.
  useEffect(() => {
    if (
      determineTyPath(path) &&
      IlxDisplay.includes(config.scData.siteVertical)
    ) {
      setLoadIlx(true);

      if (
        config.surveyName === 'homewindowsurvey.com' ||
        config.surveyName === 'americawindowsurvey.com'
      ) {
        config.siteLogo = 'hws1.png';
      }
      if (typeof window !== 'undefined' && (!window.jtknet || !window.lincx)) {
        loadScript('https://offers.printfingertech.net/js/jtknet.js');
        loadScript('https://api.lincx.com/load');

        let ty;

        if (path.includes('usl-thankyou')) {
          ty = 'usl-thankyou';
        } else if (path.includes('shi-thankyou')) {
          ty = 'shi-thankyou';
        } else if (path.includes('ermna-thankyou')) {
          ty = 'ermna-thankyou';
        } else {
          ty = '';
        }

        const jtkCheck = setInterval(() => {
          if (
            typeof window !== 'undefined' &&
            window.jtknet !== undefined &&
            window.lincx !== undefined &&
            typeof window.jtknet.fireInterlincx === 'function'
          ) {
            window.jtknet.fireInterlincx(
              config.scData.siteVertical,
              config,
              ty,
              ''
            );
            clearInterval(jtkCheck);
          }
        }, 100);
        setTimeout(() => {
          clearInterval(jtkCheck);
        }, 10000);
        return () => clearInterval(jtkCheck);
      }
    }
    // eslint-disable-next-line
  }, [path]);

  useEffect(() => {
    const params = parse(window.location.search);
    if (
      // add additional logic to this function
      determineShowRatePath(path, params) &&
      IlxDisplay.includes(config.scData.siteVertical)
    ) {
      setLoadIlx(true);
      if (typeof window !== 'undefined' && (!window.jtknet || !window.lincx)) {
        loadScript('https://offers.printfingertech.net/js/jtknet.js');
        loadScript('https://api.lincx.com/load');

        const ty = 'rates';

        const jtkCheck = setInterval(() => {
          if (
            typeof window !== 'undefined' &&
            window.jtknet !== undefined &&
            window.lincx !== undefined &&
            typeof window.jtknet.fireInterlincx === 'function'
          ) {
            window.jtknet.fireInterlincx(
              config.scData.siteVertical,
              config,
              ty,
              window?.location?.search
            );
            clearInterval(jtkCheck);
          }
        }, 100);
        setTimeout(() => {
          clearInterval(jtkCheck);
        }, 10000);
        return () => clearInterval(jtkCheck);
      }
    }
    // eslint-disable-next-line
  }, [path]);

  useEffect(() => {
    if (
      (path.includes('ty') || path.includes('usl')) &&
      IlxDisplay.includes(config.scData.siteVertical)
    ) {
      const zonesLoaded = setInterval(() => {
        const ads = document.querySelector('.offers__list');
        if (ads?.children?.length > 0) {
          setZonesLoaded(new Date().getTime());

          setTimeout(() => {
            // eslint-disable-next-line no-unused-expressions
            window?.LeadiD?.snap();
          }, 1000);
          clearInterval(zonesLoaded);
        }
      }, 100);

      return () => clearInterval(zonesLoaded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  // for ga event tracking
  useEffect(() => {
    if (path.includes('ty')) {
      setTyLoaded(new Date().getTime());
      storage('session', 'set', 'typTimeLoaded', new Date().getTime());
    }
    // eslint-disable-next-line
  }, [path]);

  // send GA event tracking
  useEffect(() => {
    if (ILXZonesLoaded && tyLoaded) {
      const uuid = storage('session', 'get', 'uuid');
      const gaData = {
        tyLoaded,
        ILXZonesLoaded,
        uuid,
        tyPage: true,
      };

      webHookTrack('Ilx_timestamp', gaData);

      g4EventTracking('Ilx_timestamp', gaData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ILXZonesLoaded, tyLoaded]);

  useEffect(() => {
    const query = parse(window.location.search);
    try {
      if (query.version) {
        switch (query.version) {
          case '1':
            setUpdatedConfig(alternateConfig1);

            break;
          case '2':
            setUpdatedConfig(alternateConfig2);
            break;
          case '3':
            setUpdatedConfig(alternateConfig3);
            break;
          case '4':
            setUpdatedConfig(alternateConfig4);
            break;
          default:
            setUpdatedConfig(config);
        }
      } else {
        setUpdatedConfig(config);
      }
    } catch (error) {
      setUpdatedConfig(config);
    }
  }, []); // eslint-disable-line

  const mortgageVerticals = ['harp', 'dpa', 'fha', '100k', 'va', 'other'];

  return (
    <>
      {updatedConfig.useTYPLayout && loadIlx && (
        <LayoutTYP
          config={config}
          bannerImages={bannerImages}
          siteImages={siteImages}
          tyPageVersion=""
        >
          <TyHeader config={config} tyPageVersion="" />
        </LayoutTYP>
      )}

      <Container path={path} loadIlx={loadIlx} className="ilxContainer">
        <div id="interLincxTarget" />
        <CredibleDisclosureContainer id="CredibleDisclosure">
          {mortgageVerticals.includes(config?.surveyType) ? (
            <>
              {' '}
              We accept advertising compensation from companies that appear on
              the site.
            </>
          ) : (
            <>
              We accept advertising compensation from companies that appear on
              the site, which impacts the location and order in which brands
              (and/or their products) are presented.
            </>
          )}
        </CredibleDisclosureContainer>
      </Container>
    </>
  );
};

ILX.propTypes = {
  path: PropTypes.string,
};

export default ILX;
