import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

import Header from '../Header';
import styles from '../../utils/customStyles';
import ConditionalWrapper from './ConditionalWrapper';
import './layout.scss';

const customStyles = styles();
const AppWrapper = styled.div`
  ${customStyles.AppWrapper}
  overflow: auto;
  width: 100%;

  #progress-bar {
    display: none;
  }
`;

// const HeadlineHeader = styled.h4`
//   ${customStyles.HeadlineHeader}
//   text-align: center;
// `;

const StyledBg = styled(BackgroundImage)`
  ${customStyles.StyledBg}
  &:before,
  &:after {
    ${customStyles.StyledBg}
  }
`;

const LayoutTYP = ({
  config,
  siteImages,
  bannerImages,
  tyPageVersion,
  children,
}) => {
  const allImages = siteImages.edges;
  const backgroundImages = bannerImages?.edges;
  const originalSiteLogo = allImages.find(image => {
    return image.node.fluid.originalName === 'logo.png';
  });
  const findFavicon = allImages.find(image => {
    return image.node.fluid.originalName === 'favicon.png';
  });
  const findGlobalImage = allImages.find(image => {
    return image.node.fluid.originalName === 'global.png';
  });
  const findBlurredImage = allImages.find(image => {
    return image.node.fluid.originalName === 'blurred.png';
  });
  const globalImage = findGlobalImage.node.fluid;
  // const favicon = findFavicon.node.fluid;
  const [siteLogo, setSiteLogo] = useState(originalSiteLogo.node.fluid);
  const [bgImage, setBgImage] = useState('');

  const findSiteLogo = logo => {
    const getSiteLogo = allImages.find(image => {
      return image.node.fluid.originalName === logo;
    });
    setSiteLogo(getSiteLogo.node.fluid);
  };

  useEffect(() => {
    const getSiteLogo = allImages.find(image => {
      return image.node.fluid.originalName === config.siteLogo;
    });
    setSiteLogo(getSiteLogo.node.fluid);
  }, [allImages, config.siteLogo]);

  useEffect(() => {
    // VWO config alteration
    window.alterConfig = (configOption, value) => {
      config[configOption] = value;
      console.log('vwo - alterConfig', configOption, value);
    };

    window.addNewClass = newClass => {
      document.body.classList.add(newClass || '');
    };
  }, [config]);

  const setBackgroundImage = (img = config.bgImage) => {
    const getBackgroundImage = backgroundImages.find(image => {
      return image.node.fluid.originalName === img;
    });
    setBgImage(getBackgroundImage?.node.fluid || '');
  };

  useEffect(() => {
    setBackgroundImage();
    // eslint-disable-next-line
  }, [bannerImages, config.bgImage]);

  return (
    <ConditionalWrapper
      condition={bgImage}
      wrapper={wrapperChildren => (
        <StyledBg
          fluid={bgImage || findBlurredImage.node.fluid}
          className={bgImage ? 'bgImage' : 'blurred-image'}
          id={bgImage ? 'background-image-main' : 'blurred-image-main'}
        >
          {wrapperChildren}
        </StyledBg>
      )}
    >
      <AppWrapper id="typWrapper">
        <Header
          className="tyHeader"
          config={config}
          id="header"
          siteLogo={siteLogo}
          globalImage={globalImage}
          findSiteLogo={findSiteLogo}
          hideHeadlineTYP
          tyHeader={tyPageVersion ? `tyHeader-${tyPageVersion}` : 'tyHeader'}
        />
        {children}
      </AppWrapper>
    </ConditionalWrapper>
  );
};

LayoutTYP.propTypes = {
  children: PropTypes.node,
  config: PropTypes.object,
  bannerImages: PropTypes.object,
  siteImages: PropTypes.object,
};

export default LayoutTYP;
