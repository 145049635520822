/* eslint-disable no-restricted-syntax */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StepOverview, StepOverview3 } from '../StepOverview';

import styles from '../../utils/customStyles';
import { StoreContext } from '../StoreContext';
import storage from '../../utils/storage';
import {
  solarZipObject,
  finalFrameSolarHeadline,
} from '../../utils/dynamic/solarZipHeadline';

const customStyles = styles();

const HeadlineContainer = styled.section`
  ${customStyles.HeadlineContainer}
`;

const HeadlineHeader = styled.h4`
  ${customStyles.HeadlineHeader}
`;

const SubHeader = styled.p`
  ${customStyles.SubHeader}
`;

const Headline = ({ html, config, params, isFinalFrame = false }) => {
  const { values, page, contextZipHeadline, questionOrder, order } = useContext(
    StoreContext
  );

  const userState = storage('session', 'get', 'userState');

  // for (const key in questionOrder) {
  //   if (Object.prototype.hasOwnProperty.call(questionOrder, key)) {
  //     const tempOrder = [...questionOrder[key]];
  //     const findQuestion = tempOrder.findIndex(obj => obj.name === fieldName);
  //     // console.log(tempOrder);
  //     // headlineId = tempOrder[page].name;
  //     console.log(findQuestion);
  //   }
  // }

  const findPageName = fieldName => {
    let field;

    for (const key in questionOrder) {
      if (Object.prototype.hasOwnProperty.call(questionOrder, key)) {
        const tempOrder = [...questionOrder[key]];
        const findQuestion = tempOrder.findIndex(obj => obj.name === fieldName);
        // console.log(findQuestion);
        if (findQuestion !== -1) {
          field = findQuestion;
        }
      }
    }
    return field;
  };

  const htmlMap = html.length ? (
    html.map((el, index) => {
      const tag = el.tag === 'p' ? SubHeader : HeadlineHeader;
      return React.createElement(tag, { key: index }, el.text);
    })
  ) : (
    <div id="HeadlineContainer" className={`stepHeadline-${page}`}>
      {config.stepOverviewAboveHeadline && (
        <StepOverview3
          page={page}
          step1={config.step1}
          step2={config.step2}
          step3={config.step3}
          isLastPage={isFinalFrame}
        />
      )}
      <HeadlineHeader id="SiteHeadline">{config.headline}</HeadlineHeader>
      <SubHeader id="SubHeadLine">{config.subhead}</SubHeader>
    </div>
  );

  if (contextZipHeadline) {
    if (values?.zipcode && values?.state) {
      return (
        <HeadlineContainer id="headine" primary>
          <HeadlineHeader>
            {`Since you live in ${
              values.state
            }, you could get a federal tax credit of $${solarZipObject[
              values.state
            ] || 0}. Tell us where to send your solar quote!`}
          </HeadlineHeader>
          <SubHeader>{config.subhead}</SubHeader>
        </HeadlineContainer>
      );
    }
    return (
      <HeadlineContainer id="headline" primary>
        <HeadlineHeader>
          Get a Federal Tax Credit When You Go Solar!
        </HeadlineHeader>
        <SubHeader>{config.subhead}</SubHeader>
      </HeadlineContainer>
    );
  }

  // Replace headline on fedscutrate.com survey
  if (params.fcrhl === '1' && config.surveyName === 'fedscutrates.com') {
    return (
      <HeadlineContainer id="headine" primary>
        <HeadlineHeader>
          ALERT: FED CUTS RATES! Eligible Homeowners Can Save Thousands a Year!
        </HeadlineHeader>
        <SubHeader>{config.subhead} </SubHeader>
      </HeadlineContainer>
    );
  }

  if (params.st && config.surveyName === 'fedscutrates.com') {
    let stateParam = params.st;
    stateParam = stateParam
      .toLowerCase()
      .split(' ')
      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    return (
      <HeadlineContainer id="headine" primary>
        <HeadlineHeader>
          {`ALERT: FED CUTS RATES! Eligible ${stateParam} Homeowners Can Save $309 / Month or More!`}
        </HeadlineHeader>
        <SubHeader>{config.subhead} </SubHeader>
      </HeadlineContainer>
    );
  }

  // Replace headline with predetermined text (100k quizes)
  if (params.hd && html.length && config.surveyType === '100k') {
    return (
      <HeadlineContainer id="headline" primary>
        {htmlMap}
        <SubHeader>{config.subhead}</SubHeader>
      </HeadlineContainer>
    );
  }
  // replace subhead, used in conjunction with 'da' param (100k quizes)
  if (params.shd && html.length && config.surveyType === '100k') {
    return (
      <HeadlineContainer id="headline" primary>
        <HeadlineHeader>{config.headline}</HeadlineHeader>
        {htmlMap}
      </HeadlineContainer>
    );
  }
  // adds text above headline (VA quizes)
  if (params.kw && config.surveyType === 'va') {
    return (
      <>
        <HeadlineContainer id="headline">{htmlMap}</HeadlineContainer>
        <HeadlineContainer primary>
          <HeadlineHeader>{config.headline}</HeadlineHeader>
          <SubHeader>{config.subhead}</SubHeader>
        </HeadlineContainer>
      </>
    );
  }

  if (params.ers && config.surveyName === 'enhancedrelief.com') {
    return (
      <HeadlineContainer id="headline" primary>
        <HeadlineHeader>
          Search Government Homeowner Programs <br /> Take this short quiz to
          see what programs are available to you and how much you can save
        </HeadlineHeader>
        <SubHeader>{config.subhead}</SubHeader>
      </HeadlineContainer>
    );
  }

  if (params.ers === '3120' && config.surveyName === 'enhanced-relief.com') {
    return (
      <HeadlineContainer id="headline" primary>
        <HeadlineHeader>
          You May Be Able To Save Up To $3120 (Average Of $260/Month) Off Your
          Mortgage With Freddie Mac’s Enhanced Relief Refinance Program!
        </HeadlineHeader>
        <SubHeader>{config.subhead}</SubHeader>
      </HeadlineContainer>
    );
  }

  if (params.rm === '1' && config.surveyName === 'enhancedrelief.com') {
    return (
      <HeadlineContainer id="headline" primary>
        <HeadlineHeader>
          With a reverse mortgage the bank pays you. Take 2 minutes to see if
          you qualify.
        </HeadlineHeader>
      </HeadlineContainer>
    );
  }

  // only run this on page load
  if (params.zipcode && config.surveyName === 'consumerdailylife.com') {
    const { city } = values;
    return (
      <HeadlineContainer id="headline" primary>
        {city === undefined || city === '' ? (
          <HeadlineHeader>{config.headline}</HeadlineHeader>
        ) : (
          <HeadlineHeader>{`Get Discounted ${city} Final Expense Rates`}</HeadlineHeader>
        )}
        <SubHeader>{config.subhead}</SubHeader>
      </HeadlineContainer>
    );
  }
  // only run this on page load
  if (config.surveyName === 'govenergyprogram.com') {
    if (params.state) {
      const state = `${params.state[0].toUpperCase()}${params.state.slice(1)}`;

      return (
        <HeadlineContainer id="headline" primary>
          {params.state === undefined || params.state === '' ? (
            <HeadlineHeader>{config.headline}</HeadlineHeader>
          ) : (
            <HeadlineHeader>{`${state} Solar Programs Offer Huge Incentives`}</HeadlineHeader>
          )}
          <SubHeader>{config.subhead}</SubHeader>
        </HeadlineContainer>
      );
    }
    if (isFinalFrame) {
      const discountValue = finalFrameSolarHeadline[
        values.state
      ]?.toLocaleString();
      let FFHeadline;

      if (config.altFFHeadline !== '' && config.altFFHeadline !== undefined) {
        FFHeadline = config.altFFHeadline;
        console.log({ FFHeadline });
      } else {
        FFHeadline = `You could get a federal tax credit${
          discountValue ? ` of $${discountValue}.` : `.`
        }`;
      }
      return (
        <HeadlineContainer id="headline" primary>
          <HeadlineHeader>{FFHeadline}</HeadlineHeader>

          <SubHeader>Tell us where to send your quote!</SubHeader>
        </HeadlineContainer>
      );
    }
  }

  if (config.surveyName === 'qualifiedsolarsurvey.com') {
    if (isFinalFrame) {
      const discountValue = finalFrameSolarHeadline[
        values.state
      ]?.toLocaleString();

      let FFHeadline;

      if (config.altFFHeadline !== '' && config.altFFHeadline !== undefined) {
        FFHeadline = config.altFFHeadline;
      } else {
        FFHeadline = `You could get a federal tax credit${
          discountValue ? ` of $${discountValue}.` : `.`
        }`;
      }
      return (
        <HeadlineContainer id="headline" primary>
          <HeadlineHeader>{FFHeadline}</HeadlineHeader>

          <SubHeader>Tell us where to send your quote!</SubHeader>
        </HeadlineContainer>
      );
    }
  }

  // FEDP-2284 - param to add vehicle to headline
  if (params.vm && config.surveyName === 'autogo2.insurancespecialists.com') {
    const str = `${params.vm[0].toUpperCase()}${params.vm.slice(1)}`;
    return (
      <HeadlineContainer id="headline" primary>
        <HeadlineHeader>
          Lets Drop Your Rate Today On Your {str}!
        </HeadlineHeader>
      </HeadlineContainer>
    );
  }

  if (
    config.verifyPage &&
    typeof window !== 'undefined' &&
    window.location.pathname !== '/'
  ) {
    return (
      <HeadlineContainer id="headline" secondary>
        <HeadlineHeader secondary>{config.singlePageHeader}</HeadlineHeader>
      </HeadlineContainer>
    );
  }

  if (
    config.surveyName === 'rates2.ratemarketplace.com' &&
    config.rateInHeadline
  ) {
    return (
      <HeadlineContainer id="headline" primary>
        <div className="aprContainer">
          <div className="aprImage" />
          <div className="aprWording">
            <p className="aprPercent">
              {config.rateInHeadline}{' '}
              <span className="aprSpan">
                APR<sup>**</sup>
              </span>
            </p>
            <p className="currentRates">Current Rates</p>
          </div>
        </div>
        <div className="headlineMapContainer">{htmlMap}</div>
      </HeadlineContainer>
    );
  }
  if (config.headerBackgroundImage) {
    return (
      <HeadlineContainer id="headline" primary>
        <div className="headerImageContainer">
          <div className="headlineImage" />
        </div>
        <div id="HeadlineContainer">
          <HeadlineHeader id="SiteHeadline">{config.headline}</HeadlineHeader>
        </div>
        <div id="SubHeadlineContainer">
          <SubHeader id="SubHeadLine">{config.subhead}</SubHeader>
        </div>
        {/* {htmlMap} */}
      </HeadlineContainer>
    );
  }

  // Return headline if no params
  return (
    <HeadlineContainer id="headline" primary>
      {htmlMap}
      {config.stepOverviewInHeadline && <StepOverview />}
      {config.additionalHeadlineText1 && (
        <>
          <div className="additionalHeadlineText">
            <div className="icon-checkmark" />
            {config.additionalHeadlineText1}
          </div>
          <div className="additionalHeadlineText">
            <div className="icon-checkmark" />
            {config.additionalHeadlineText2}
          </div>
          <div className="additionalHeadlineText">
            <div className="icon-checkmark" />
            {config.additionalHeadlineText3}
          </div>
          <div className="additionalHeadlineText">
            <div className="icon-checkmark" />
            {config.additionalHeadlineText4}
          </div>
          <div className="additionalHeadlineText">
            <div className="icon-checkmark" />
            {config.additionalHeadlineText5}
          </div>
          <div className="additionalHeadlineText">
            <div className="icon-checkmark" />
            {config.additionalHeadlineText6}
          </div>
          <div className="additionalHeadlineText">
            <div className="icon-checkmark" />
            {config.additionalHeadlineText7}
          </div>
          <div className="additionalHeadlineText">
            <div className="icon-checkmark" />
            {config.additionalHeadlineText8}
          </div>
          <div className="additionalHeadlineText">
            <div className="icon-checkmark" />
            {config.additionalHeadlineText9}
          </div>
        </>
      )}
    </HeadlineContainer>
  );
};

Headline.propTypes = {
  html: PropTypes.array,
  config: PropTypes.shape({
    surveyName: PropTypes.string,
    additionalHeadlineText1: PropTypes.string,
    additionalHeadlineText2: PropTypes.string,
    additionalHeadlineText3: PropTypes.string,
    additionalHeadlineText4: PropTypes.string,
    additionalHeadlineText5: PropTypes.string,
    additionalHeadlineText6: PropTypes.string,
    additionalHeadlineText7: PropTypes.string,
    additionalHeadlineText8: PropTypes.string,
    additionalHeadlineText9: PropTypes.string,
    singlePageHeader: PropTypes.string,
    verifyPage: PropTypes.bool,
    headerBackgroundImage: PropTypes.bool,
    headline: PropTypes.string,
    subhead: PropTypes.string,
    surveyType: PropTypes.string,
    stepOverviewInHeadline: PropTypes.bool,
    altFFHeadline: PropTypes.string,
    siteHeadline: PropTypes.bool,
  }),
  params: PropTypes.object,
};
const MemoizedHeadline = React.memo(Headline);
export default MemoizedHeadline;
