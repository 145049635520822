/**
 * Used by `storage` function to see if the type of storage is available on the window.
 * @param  {string} type
 */
const localData = {};

const storageAvailable = type => {
  try {
    const tempStorage = window[type];
    const x = '__storage_test__';
    tempStorage.setItem(x, x);
    tempStorage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Stores all function data to a "global" variable
 * If user deletes any client storage data this function will grabe the global variable property instead
 * Uses session/local storage is a user has not blocked cookies.
 * @param  {string} type
 * @param  {string} itemType
 * @param  {string} key
 * @param  {object, string, array} value
 */
const storage = (type, itemType, key, value) => {
  // variable to be returned
  let getValue = '';

  // sets each storage function call to "global" memory
  try {
    if (itemType === 'get') {
      getValue = localData[key];
    } else if (itemType === 'set') {
      localData[key] = value;
    } else if (itemType === 'remove') {
      delete localData[key];
    }
  } catch (error) {
    console.log(error);
  }

  // checks if client storage is available and stores data there as well
  if (storageAvailable(`${type}Storage`)) {
    if (type === 'session') {
      if (itemType === 'get') {
        getValue = sessionStorage.getItem(key, value);
      } else if (itemType === 'set') {
        sessionStorage.setItem(key, value);
      } else if (itemType === 'remove') {
        sessionStorage.removeItem(key, value);
      } else if (itemType === 'clear') {
        sessionStorage.clear();
      }
    } else if (type === 'local') {
      if (itemType === 'get') {
        getValue = localStorage.getItem(key, value);
        if (getValue === null) {
          getValue = localData[key];
        }
      } else if (itemType === 'set') {
        localStorage.setItem(key, value);
      } else if (itemType === 'remove') {
        localStorage.removeItem(key, value);
      } else if (itemType === 'clear') {
        localStorage.clear();
      }
    }
  }

  // checks to see if getValue is null or undefined, if so then return the "global" value from memory
  if (getValue === null || getValue === undefined) {
    return localData[key];
  }
  return getValue;
};

export default storage;
