/**
 * Dynamic request for all API calls
 * @param {string} event_name
 * @param {object} payload
 */

export const webHookTrack = (event_name, payload) => {
  try {
    const eventData = {
      event_name,
      payload,
    };
    const options = {
      method: 'POST',
      headers: {
        'X-Edw-Secret-Token': `CD9T/QZcPKxai+Frv/6++XvOtliX9+gnykE23Vmcsdo`,
        'Content-Type': 'application/json',
      },
      crossDomain: true,
      body: JSON.stringify(eventData),
    };
    fetch(
      'https://edwapi-prod.printfinger.tech/webhook/13cc5ce4-4f2e-4811-a886-c20eeabd1dd5',
      options
    )
      .then(response => response.json())
      // .then(response => console.log(response))
      .catch(err => console.error(err));
  } catch (error) {
    console.log('🚀 ~ file: g4Tracking.js:27 ~ error:', error);
  }
};
