const trustedForm = async () => {
  const field = 'xxTrustedFormCertUrl';
  const provideReferrer = false;
  const invertFieldSensitivity = false;
  const tf = document.createElement('script');
  tf.type = 'text/javascript';
  tf.crossorigin = 'use-credentials';
  tf.async = true;
  tf.src = `http${
    document.location.protocol === 'https:' ? 's' : ''
  }://cdn.trustedform.com/bootstrap.js?provide_referrer=${escape(
    provideReferrer
  )}&field=${escape(
    field
  )}&l=${new Date().getTime()}${Math.random()}&invert_field_sensitivity=${invertFieldSensitivity}`;

  const s = document.getElementsByTagName('script')[0];
  if (s) {
    // s.parentNode.insertBefore(tf, s);
    document.body.appendChild(tf);
  }
};

export { trustedForm };
