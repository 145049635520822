import { parse } from 'query-string';
import { webHookTrack } from './dynamic/webhookTrack';
import storage from './storage';
import { zoneHeightTest } from './adButlerTests';
import { g4EventTracking } from './dynamic/g4Tracking';

// redirects users if any of these affiliates are used
const blackListed = a => {
  const affiliates = ['1668', '1672', '1719', '554'];
  if (typeof window !== 'undefined') {
    if (
      a === '1127' &&
      window.location.hostname === 'v2.downpaymentsurvey.com'
    ) {
      window.location.href = 'https://downpaymentsurvey.com';
    } else if (
      a === '1127' &&
      window.location.hostname === 'solarincentiveprograms.com'
    ) {
      window.location.href = 'https://qualifiedsolarsurvey.com';
    } else if (
      a === '1975' &&
      window.location.hostname === 'qualifiedsolarsurvey.com'
    ) {
      console.log('goodbye');
      window.location.href = 'https://google.com';
    } else {
      for (let i = 0; i < affiliates.length; i += 1) {
        if (a === affiliates[i]) {
          console.log('goodbye');
          window.location.href = 'https://google.com';
        }
      }
    }
  }
  return false;
};

// utility function to find the closes value in an array.
const findClosest = (val, arr) => {
  let closest = Math.max.apply(null, arr);
  for (let i = 0; i < arr.length; i += 1) {
    if (arr[i] >= val && arr[i] < closest) closest = arr[i];
  }
  return closest;
};

// a promise that returns a timeout ** Might use it somewhere **
const waitFor = ms => new Promise(r => setTimeout(r, ms));

const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const arrayMove = (arr, oldIndex, newIndex) => {
  if (newIndex >= arr.length) {
    const k = newIndex - arr.length + 1;
    while (k - k - 1) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

const redirectOffer = lend => {
  let offer = '';
  switch (lend) {
    case 'cc':
      // currently no redirect offer
      offer = '';
      break;
    case 'ql':
      // Quicken Loans
      offer = 'https://suited45trk.com/?E=D0KFTl2JX6PdYfJtKbKAG%2bcV2pp1jy6g';
      break;
    case 'ld':
      // Loan Depot
      offer = 'https://suited45trk.com/?E=1FsBSNQogQxWgIBRnHH%2fWecV2pp1jy6g';
      break;
    case 'as':
      // Amerisave
      offer = 'https://suited45trk.com/?E=s7PMznWlEdVdPKUI3F%2bqeecV2pp1jy6g';
      break;
    case 'as2':
      // Amerisave 2
      offer = 'https://suited45trk.com/?E=Drb54V4PGOUsAdTW%2fCD2xkmCTIhTDU%2fQ';
      break;
    case 'lmb':
      // currently no redirect offer
      break;
    default:
      // added here just in case
      offer = '';
      break;
  }
  const loadingSpinner = document.getElementById('loadingSpinner');
  loadingSpinner.style.display = 'flex';
  setTimeout(() => {
    window.location.href = offer;
  }, 500);
};
const redirectOfferCl = lend => {
  const loadingSpinnerText = document.getElementById('loading-spinner-text');
  let offer = '';
  switch (lend) {
    case 'cc':
      offer = 'https://suited45trk.com/?E=vy5oqAaQRoHL7MfnfSM7POcV2pp1jy6g';
      break;
    case 'ql':
      // Quicken Loans
      offer = '';
      break;
    case 'ld':
      // Loan Depot
      offer = 'https://suited45trk.com/?E=cTCXVUbfDYn%2fJE8sqJH1eucV2pp1jy6g';
      break;
    case 'as':
      // Amerisave
      offer = 'https://suited45trk.com/?E=GhP94l2icRjG7OB8qGiQkecV2pp1jy6g';
      break;
    case 'cym':
      offer = 'https://suited45trk.com/?E=YC4v9BlnAMnjh6OLB5ZD3ucV2pp1jy6g';
      loadingSpinnerText.innerHTML = 'Start comparing lenders and get matched';
      break;
    case 'as2':
      // Amerisave 2
      offer = '';
      break;
    case 'lmb':
      offer = 'https://suited45trk.com/?E=9D7VKiYNTSzh%2bNMQQVRlOucV2pp1jy6g';
      loadingSpinnerText.innerHTML = 'Start comparing lenders and get matched';
      break;
    default:
      // added here just in case
      offer = 'https://suited45trk.com/?E=Mqbe839H9ta%2bNXcw5fGq1OcV2pp1jy6g';
      break;
  }
  const loadingSpinner = document.getElementById('loadingSpinner');
  loadingSpinner.style.display = 'flex';
  setTimeout(() => {
    window.location.href = offer;
  }, 500);
};

const getSourceId = (config, aff = '') => {
  let pageCode = '';
  switch (config.scData.siteVertical) {
    case 'fha':
    case 'harp':
    case 'dpa':
    case 'va':
    case 'RTO':
      pageCode = 'MORBB';
      break;
    case 'ins_auto':
      pageCode = 'AUTBB';
      break;
    case 'ins_health':
      pageCode = 'HIIBB';
      break;
    case 'ins_home':
      pageCode = 'HIOBB';
      break;
    case 'ins_life':
      pageCode = 'LIIBB';
      break;
    case 'hs_solar':
      pageCode = 'SOLBB';
      break;
    case 'hs_roofing':
      pageCode = 'ROOBB';
      break;
    case 'hs_window':
      pageCode = 'WINBB';
      break;
    case 'hs_ha':
      pageCode = 'HSVBB';
      break;
    case 'hs_br':
      pageCode = 'HSBRBB';
      break;
    default:
      pageCode = '';
  }

  if (pageCode && aff) {
    return `${pageCode}-${aff}`;
  }

  return pageCode;
};

/**
 * Fires in App.js if there's formData in localStorage
 * Deletes the main Cake Data from
 * @param  {object} getForm
 */
const cleanLSFormData = getForm => {
  if (getForm.ckm_subid_1 || getForm.s1) {
    delete getForm.ckm_subid_1;
    delete getForm.s1;
  }
  if (getForm.ckm_subid_2 || getForm.s2) {
    delete getForm.ckm_subid_2;
    delete getForm.s2;
  }
  if (getForm.ckm_subid_3 || getForm.s3) {
    delete getForm.ckm_subid_3;
    delete getForm.s3;
  }
  if (getForm.ckm_subid_4 || getForm.s4) {
    delete getForm.ckm_subid_4;
    delete getForm.s4;
  }
  if (getForm.ckm_subid_5 || getForm.s5) {
    delete getForm.ckm_subid_5;
    delete getForm.s5;
  }
  if (getForm.uuid) {
    delete getForm.uuid;
  }
  if (getForm.throttle_id) {
    delete getForm.throttle_id;
  }
  if (getForm.site_url) {
    delete getForm.site_url;
  }
  if (getForm.xxTrustedFormCertUrl || getForm.xxTrustedFormToken) {
    delete getForm.xxTrustedFormCertUrl;
    delete getForm.xxTrustedFormToken;
  }
  if (getForm.universal_leadid) {
    delete getForm.universal_leadid;
  }
  return getForm;
};

const coinFlip = () => {
  return Math.random() < 0.5;
};

const resizeIframes = async (uuid, config, coinFlipRes) => {
  const findIframes = document && document.getElementsByTagName('iframe');
  const params = parse(window.location.search);

  let zoneOverrides = [];
  try {
    const zoneOverrideFetch = await fetch(
      'https://s3.us-west-1.amazonaws.com/offers.printfingertech.net/js/zone-height-overrides.json'
    );
    zoneOverrides = await zoneOverrideFetch.json();
  } catch (error) {
    console.log(error);
  }

  const checkZoneOverride = elementId => {
    const zoneId = parseInt(elementId.split('_')[1]);
    const zoneObj = zoneOverrides.filter(obj => obj.zoneId === zoneId);
    return zoneObj;
  };

  if (findIframes) {
    let counter = 0;
    const adButlerInterval = setInterval(() => {
      Array.prototype.forEach.call(findIframes, element => {
        if (element.id.includes('placement')) {
          try {
            const iframeBody = element?.contentWindow?.document?.body;

            if (iframeBody) {
              iframeBody.onclick = function() {
                webHookTrack('ad_click', { adId: element.id });

                g4EventTracking('ad_click', { adId: element.id });
                if (uuid) {
                  webHookTrack('ad_click_uuid', { adUUID: uuid });

                  g4EventTracking('ad_click_uuid', { adUUID: uuid });
                }
              };
            }

            // gets the rates offer class element
            const ratesOffer = iframeBody.childNodes[0].querySelector(
              '.rates-offer'
            );

            let scrollHeight = iframeBody?.scrollHeight + 40;

            // sets the height of the iframe to 0 if the rates-offer element is set to display: none
            if (ratesOffer && ratesOffer !== null) {
              const compStyles =
                typeof window !== `undefined` &&
                window.getComputedStyle(ratesOffer);

              if (compStyles && compStyles.display === 'none') {
                scrollHeight = iframeBody?.scrollHeight;
                element.style.height = `${scrollHeight}px`; // should equal 0 every time but not hardcoding it just inc
              }
            }

            // TYP JTKSPlits Testing will clear the interval on this zone as it's responsive
            if (element.id.includes('582616')) {
              scrollHeight = iframeBody?.scrollHeight;

              if (counter > 5) {
                clearInterval(adButlerInterval);
              }
              counter += 1;
            }

            if (element.id.includes('444949')) {
              scrollHeight = iframeBody?.scrollHeight;
            }

            if (iframeBody.scrollHeight < 20) {
              element.style.height = '1px';
              if (element.style.height === '1px') {
                element.style.marginBottom = '0';
              } else {
                element.style.marginBottom = '1.45rem';
              }
            } else if (
              scrollHeight &&
              `${scrollHeight}px` !== element.style.height
            ) {
              // set override here
              if (zoneOverrides.length > 0) {
                const viewportWidth =
                  typeof window !== `undefined` && window.innerWidth;

                // returns the zone override object if it exists
                const overrideObj = checkZoneOverride(element.id);

                if (overrideObj.length > 0) {
                  if (
                    (overrideObj[0].typTestD || overrideObj[0].typTestM) &&
                    zoneHeightTest(config, coinFlipRes)
                  ) {
                    if (viewportWidth < 768) {
                      element.style.height = `${overrideObj[0].typTestM ||
                        scrollHeight}px`;
                    } else {
                      element.style.height = `${overrideObj[0].typTestD ||
                        scrollHeight}px`;
                    }
                  } else {
                    // eslint-disable-next-line
                    if (viewportWidth < 768) {
                      element.style.height = `${overrideObj[0].mobile ||
                        scrollHeight}px`;
                    } else {
                      element.style.height = `${overrideObj[0].height ||
                        scrollHeight}px`;
                    }
                  }
                } else {
                  element.style.height = `${scrollHeight}px`;
                }
              } else {
                console.log('no zone overrides');
                element.style.height = `${scrollHeight}px`;
              }
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
    }, 1000);
    setTimeout(() => {
      clearInterval(adButlerInterval);
    }, 10000);
  }
};

const removeDupArrItems = array => {
  const a = array.concat();
  for (let i = 0; i < a.length; i += 1) {
    for (let j = i + 1; j < a.length; j += 1) {
      // eslint-disable-next-line no-plusplus
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }

  return a;
};

const getData = param => {
  if (
    param !== '' &&
    param !== null &&
    param !== undefined &&
    param !== 'null' &&
    param !== 'undefined'
  ) {
    if (param === 'vendor') {
      const vendor = sessionStorage.getItem('AffiliateAssociation') || '';
      if (vendor) {
        console.log(`${param}  association found in Storage`);
      }
      return vendor;
      /* eslint-disable */
    } else {
      const data = sessionStorage.getItem('formData');
      if (
        data !== '' &&
        data !== null &&
        data !== undefined &&
        data !== 'null' &&
        data !== 'undefined'
      ) {
        const parsed = JSON.parse(data);

        if (parsed[param]) {
          // translate between URL param and storage properties
          switch (param) {
            case 'o':
              param = 'offer_id';
              break;
            case 'a':
              param = 'affiliate_id';
              break;
            case 'cpid':
              param = 'campaign_id';
              break;
            case 's1':
              param = 'ckm_subid_1';
              break;
            case 's2':
              param = 'ckm_subid_2';
              break;
            case 's3':
              param = 'ckm_subid_3';
              break;
            case 's4':
              param = 'ckm_subid_4';
              break;
            case 's5':
              param = 'ckm_subid_5';
              break;
            default:
              // use original param
              break;
          }

          return parsed[param];
        }
      } else {
        if (param === 'uuid') {
          return sessionStorage.getItem('scUUID') || '';
        }
      }
    }
  }
  return '';
};

// Get user Ip Address
const getUserIp = async tempParams => {
  let values = { ...tempParams };
  try {
    const address = await fetch('https://api.ipify.org?format=json');
    if (address.status === 200) {
      const result = await address.json();
      // tempParams.user_ip = result.ip;
      values = { ...tempParams, user_ip: result.ip };
    } else {
      values = { ...tempParams, user_ip: null };
    }
  } catch (err) {
    values = { ...tempParams, user_ip: null };
  }
  return values;
};

const getUserIPInfo = async tempParams => {
  let values = { ...tempParams };
  try {
    const call = await fetch(
      `https://ipapi.co/json/?key=rLBVazTbhCesxEGft6N3aI0Cu6Q7Wbhwe5jSkAYPAFnw6o7NLE`
    );
    if (call.status === 200) {
      const ipData = await call.json();
      values = {
        ...tempParams,
        userState: ipData.region,
        userStateCode: ipData.region_code,
        userCity: ipData.city,
        userZip: ipData.postal,
        userCountry: ipData.country,
      };
      storage('session', 'set', 'userState', ipData.region);
    } else {
      console.log('apapi call failed');
    }
  } catch (err) {
    console.log(err);
  }
  return values;
};

const setZipCode = async (
  tempParams,
  dynamicSetState,
  query,
  initialValues
) => {
  const res = await getUserIPInfo();
  if (res.userZip && res.userCountry === 'US' && !query.zipcode) {
    dynamicSetState('initialValues', {
      ...initialValues,
      zipcode: res.userZip,
      city: res.userCity,
      state: res.userStateCode,
    });
  }
};

const updateHeadlineWithIpInfo = async (
  locationOption,
  headlineA,
  headlineB = '',
  tempParams,
  setHeadline,
  config
) => {
  const stateUpdated = setInterval(() => {
    switch (locationOption) {
      case 'state':
        if (tempParams.userState) {
          setHeadline([
            {
              tag: 'h2',
              text: `${headlineA} ${tempParams.userState}${headlineB}`,
            },
            {
              tag: 'p',
              text: `${config.subhead}`,
            },
          ]);
          clearInterval(stateUpdated);
        }
        break;
      case 'city':
        if (tempParams.userCity) {
          setHeadline([
            {
              tag: 'h2',
              text: `${tempParams.userCity} ${headlineA} ${headlineB}`,
            },
          ]);
          clearInterval(stateUpdated);
        }
        break;
      case 'zipcode':
        if (tempParams.userZip) {
          setHeadline([
            {
              tag: 'h2',
              text: `${headlineA} ${tempParams.userZip} ${headlineB}`,
            },
            {
              tag: 'p',
              text: `${config.subhead}`,
            },
          ]);
          clearInterval(stateUpdated);
        }
        break;
      default:
        setHeadline([
          {
            tag: 'h2',
            text: `${config.headline}`,
          },
        ]);
    }
  }, 250);
  setTimeout(() => {
    clearInterval(stateUpdated);
  }, 3000);
};

const updateSubHeadWithIpInfo = async (
  dynamicSubA = '',
  dynamicSubB = '',
  pattern,
  tempParams,
  setHeadline,
  config
) => {
  const date = new Date().toLocaleDateString();
  await getUserIPInfo();
  if (tempParams.userState && tempParams.userCountry === 'US') {
    switch (pattern) {
      case 'a':
        setHeadline([
          {
            tag: 'h2',
            text: `${config.headline}`,
          },
          {
            tag: 'p',
            text: `${dynamicSubA} ${tempParams.userState} ${dynamicSubB}`,
          },
        ]);
        break;
      case 'b':
        setHeadline([
          {
            tag: 'h2',
            text: `${config.headline}`,
          },
          {
            tag: 'p',
            text: `${dynamicSubA} ${tempParams.userCity} ${dynamicSubB}`,
          },
        ]);
        break;
      case 'c':
        setHeadline([
          {
            tag: 'h2',
            text: `${config.headline}`,
          },
          {
            tag: 'p',
            text: `${dynamicSubA} ${date} in ${tempParams.userState}${dynamicSubB}`,
          },
        ]);
        break;
      case 'd':
        setHeadline([
          {
            tag: 'h2',
            text: `${config.headline}`,
          },
          {
            tag: 'p',
            text: `${dynamicSubA} ${date} in ${tempParams.userCity}${dynamicSubB}`,
          },
        ]);
        break;
      default:
        setHeadline([
          {
            tag: 'h2',
            text: `${config.headline}`,
          },
          {
            tag: 'p',
            text: `${dynamicSubA} ${dynamicSubB}`,
          },
        ]);
    }
  } else {
    setHeadline([
      {
        tag: 'h2',
        text: `${config.headline}`,
      },
      {
        tag: 'p',
        text: `${config.subhead}`,
      },
    ]);
  }
};
// will return true 10% of the time
const is10Percent = () => Math.random() < 0.1;

export {
  is10Percent,
  blackListed,
  findClosest,
  arrayMove,
  waitFor,
  capitalize,
  redirectOffer,
  redirectOfferCl,
  getSourceId,
  cleanLSFormData,
  resizeIframes,
  removeDupArrItems,
  getData,
  getUserIp,
  getUserIPInfo,
  setZipCode,
  updateHeadlineWithIpInfo,
  updateSubHeadWithIpInfo,
  coinFlip,
};
