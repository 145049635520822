import React, { useContext, Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StoreContext } from '../StoreContext';
import {
  ProgressContainer,
  StatusBar,
  ProgressCircleContainer,
  Clip1,
  Clip2,
  Slice1,
  Slice2,
  NumberStatus,
  FooterNumberStatus,
  ProgressBarContainer,
} from './ProgressBarStyles';

const ProgressBar = ({ type, config, renderedFrom = '' }) => {
  const [displayPercentage, setDisplayPercentage] = useState(2);
  const { page, quizLength, order } = useContext(StoreContext);
  const findIndex = order && order.indexOf(page);
  const width = `${((findIndex >= 0 ? findIndex : page) /
    (order.length ? order.length - 1 : quizLength - 1)) *
    86}`;

  const deg1 = width < 50 ? (width * 360) / 100 : '180';
  const deg2 = width > 50 ? (width * 360) / 100 / 2 : '0';

  useEffect(() => {
    const totalLength = order.length ? order.length - 1 : quizLength - 1;
    const pageNumber = findIndex >= 0 ? findIndex : page;
    if (type !== 'circle') {
      if (pageNumber && totalLength > 0) {
        const oneThird = Math.ceil(totalLength / 3);
        const firstThird = 50 / oneThird;
        const secondThird = 35 / oneThird;
        const lastThird = 12 / oneThird;
        if (pageNumber <= oneThird) {
          setDisplayPercentage(Math.round(pageNumber * firstThird));
        } else if (pageNumber <= oneThird * 2) {
          setDisplayPercentage(
            Math.round((pageNumber - oneThird) * secondThird + 50)
          );
        } else {
          setDisplayPercentage(
            Math.round((pageNumber - oneThird * 2) * lastThird + 85)
          );
        }
      }
    } else {
      // eslint-disable-next-line
      if (Math.floor(width) === 90) {
        setDisplayPercentage(97);
      } else if (type === 'circle' && +width === 0) {
        setDisplayPercentage(0);
      } else {
        setDisplayPercentage(Math.round(+width + 10));
      }
    }
    // eslint-disable-next-line
  }, [page, order]);

  // let displayPercentage;
  // if (Math.floor(width) === 90) {
  //   displayPercentage = 97;
  // } else if (type === 'circle' && +width === 0) {
  //   displayPercentage = 0;
  // } else {
  //   displayPercentage = Math.round(+width + 10);
  // }

  switch (type) {
    case 'circle':
      return (
        <ProgressCircleContainer id="progress-bar">
          <Clip1>
            <Slice1 transform={`${deg1}deg`} />
          </Clip1>
          <Clip2>
            <Slice2 transform={`${deg2}deg`} />
          </Clip2>
          <NumberStatus>{displayPercentage}%</NumberStatus>
        </ProgressCircleContainer>
      );
    case 'footer':
      return (
        <ProgressContainer
          id="progress-bar"
          style={{ position: 'fixed', bottom: '0', left: '0', zIndex: '99' }}
        >
          <StatusBar id="progress-status" width={`${displayPercentage}%`} />
          <FooterNumberStatus id="progress-number">
            {displayPercentage}% Complete
          </FooterNumberStatus>
        </ProgressContainer>
      );
    case 'none':
      return null;
    case 'bar-status':
      return (
        <>
          <ProgressContainer id="progress-bar">
            <StatusBar width={`${displayPercentage}%`} />
          </ProgressContainer>
          <NumberStatus id="progressPercentage">
            {displayPercentage}%
          </NumberStatus>
        </>
      );
    case 'bar-status-w-complete':
      return (
        <ProgressBarContainer id="progressContainer">
          <ProgressContainer id="progress-bar">
            <StatusBar width={`${displayPercentage}%`} />
          </ProgressContainer>
          <NumberStatus id="progressPercentage">
            {displayPercentage}% complete
          </NumberStatus>
        </ProgressBarContainer>
      );
    case 'status-inside-bar-w-container':
      return (
        <ProgressBarContainer id="progressContainer">
          <ProgressContainer id="progress-bar">
            <NumberStatus id="progressPercentage">
              {displayPercentage}%
            </NumberStatus>
            <StatusBar width={`${displayPercentage}%`} />
          </ProgressContainer>
        </ProgressBarContainer>
      );
    case 'status-inside-bar-w-container-w-complete':
      return (
        <ProgressBarContainer id="progressContainer">
          <ProgressContainer id="progress-bar">
            <NumberStatus id="progressPercentage">
              {displayPercentage}% complete
            </NumberStatus>
            <StatusBar width={`${displayPercentage}%`} />
          </ProgressContainer>
        </ProgressBarContainer>
      );
    case 'status-inside-bar':
      return (
        <>
          <ProgressContainer id="progress-bar">
            <StatusBar width={`${displayPercentage}%`}>
              <NumberStatus id="progressPercentage">
                {displayPercentage}%
              </NumberStatus>
            </StatusBar>
          </ProgressContainer>
        </>
      );
    case 'inside-survey':
    case 'insideForm':
      return (
        <Fragment>
          <FooterNumberStatus id="your-progress">
            Your Progress
          </FooterNumberStatus>
          <ProgressContainer id={`progress-bar-${renderedFrom}`}>
            <StatusBar id="status-bar" width={`${displayPercentage}%`} />
          </ProgressContainer>
        </Fragment>
      );
    case 'inside-survey-with-complete':
      return (
        <ProgressBarContainer id="progressContainer">
          <ProgressContainer id="progress-bar">
            <StatusBar width={`${displayPercentage}%`} />
          </ProgressContainer>
          <NumberStatus id="progressPercentage">
            {displayPercentage}% complete
          </NumberStatus>
        </ProgressBarContainer>
      );
    default:
      // 'bar' is default - progressbar on the top of the screen.
      return (
        <ProgressContainer id="progress-bar">
          <StatusBar id="status-bar" width={`${displayPercentage}%`} />
        </ProgressContainer>
      );
  }
};

ProgressBar.propTypes = {
  type: PropTypes.string,
};

const MemoizedProgressBar = React.memo(ProgressBar);
export default MemoizedProgressBar;
