import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import styles from '../utils/customStyles';

const customStyles = styles();

const GlobalSignImg = styled(Img)`
  width: 100%;
  ${customStyles.GlobalSealImg}
`;

const GlobalSign = ({ globalImage, altText = 'Secure Icon' }) => (
  <section id="global-seal" data-testid="global-seal">
    <GlobalSignImg fluid={globalImage} alt={altText} />
  </section>
);

export default GlobalSign;
